const PATH_SETTING = 'wallet/PATH_SETTING' as const;
const PATH_FIRST_SETTING = 'wallet/PATH_FIRST_SETTING' as const;

type GNBAction =
  | ReturnType<typeof pathSetting>
  | ReturnType<typeof pathFirstSetting>
;

export const pathSetting = (diff: string) => ({
  type: PATH_SETTING,
  payload: diff
});

export const pathFirstSetting = (diff: string) => ({
  type: PATH_FIRST_SETTING,
  payload: diff
});

export type PathState = {
  nowPath: string;
  firstPath: string;
};

const initialState: PathState = {
  nowPath: "",
  firstPath: ""
};

function path(
  state: PathState = initialState,
  action: GNBAction
): PathState {
  switch (action.type) {
    case PATH_SETTING:
      return { ...state, nowPath: action.payload };
    case PATH_FIRST_SETTING:
        return { ...state, firstPath: action.payload };
    default:
      return state;
  }
}

export default path;