import CryptoJS from "crypto-js";

/**
 * 암호화 모듈
 * TODO 아직 구현을 안해서 사용할 때 구현 필요
 * @param date 암호화 key 
 * @param message 암호화 시킬 메시지
 * 
 * @returns 암호화 된 값
 */
export function encrypto(date: number, message: string) {
  const date_string = date.toString();

  // return date_string;
  return CryptoJS.AES.encrypt(message, date_string).toString();
}