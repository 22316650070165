import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";

import { NormalButton } from '@components/index';

import './registerDone.scss';

/**
 * /register/done - 가입 완료 페이지
 */

const RegisterDone = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="content register-done">
      <div className="done">
        <div className="done__base">
          <div className="done__wrapper">
            <div className="done__header">
              <p className="done__title">{t("done_zemvierse")}</p>
              <div className="log-wrapper">
                <i className="log-icon check"></i>
                <p>{t("done_complate")}</p>
              </div>
            </div>
            <div className="done__description">{t("done_description")}</div>
            <div className="done-button__wrapper">
              <NormalButton 
                title={t("done_mian")}
                hierarchy="primary" 
                onClick={() => {
                  navigate(`/zemiverse`);
                }} 
                buttonStyle="round" 
                size="xl" 
                // disable={true} 
                width={isMobile? "100%" : "280px" } 
                height="48px" 
                icon="trailing" 
                buttonClassName="to_main"
              />              
              <NormalButton 
                title={t("done_mypage")}
                hierarchy="secondary_gray" 
                onClick={() => {
                  navigate(`/mypage`);
                }} 
                buttonStyle="round" 
                size="xl" 
                // disable={true} 
                width={isMobile ? "100%" : "280px" } 
                height="48px" 
                icon="trailing" 
                buttonClassName="my_page"
              />   
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterDone;