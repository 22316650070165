const MYMENU_SETTING = 'mymenu/MYMENU_SETTING' as const;

type MYMENUAction =
  | ReturnType<typeof mymenuSetting>
;

export const mymenuSetting = (diff: boolean) => ({
  type: MYMENU_SETTING,
  payload: diff
});

export type MYMENUState = {
  open: boolean;
};

const initialState: MYMENUState = {
  open: false,
};

function mymenu(
  state: MYMENUState = initialState,
  action: MYMENUAction
): MYMENUState {
  switch (action.type) {
    case MYMENU_SETTING:
      return { ...state, open: action.payload };
    default:
      return state;
  }
}

export default mymenu;