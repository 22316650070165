const SEARCH_MODAL_SETTING = 'modal/SEARCH_MODAL_SETTING' as const;

type ModalAction =
  | ReturnType<typeof searchModalSetting>
;

export const searchModalSetting = (diff: boolean) => ({
  type: SEARCH_MODAL_SETTING,
  payload: diff
});

export type SearchModalState = {
  open: boolean;
};

const initialState: SearchModalState = {
  open: false,
};

function searchModal(
  state: SearchModalState = initialState,
  action: ModalAction
): SearchModalState {
  switch (action.type) {
    case SEARCH_MODAL_SETTING: 
      return { ...state, open: action.payload };
    default:
      return state;
  }
}

export default searchModal;