import './mainHeader230622.scss';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import { NormalButton, MyMenu, MainGnb } from '@components/index';

import { RootState, GNBState, gnbSetting, SearchModalState, LoginState, WindowState, mymenuSetting } from "@store/index";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { debounce } from "lodash";

const MainHeader230622 = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const gnb: GNBState = useSelector((state: RootState) => state.gnb);
  const searchModal: SearchModalState = useSelector((state: RootState) => state.searchModal);
  const login: LoginState = useSelector((state: RootState) => state.login);
  const windowSize: WindowState = useSelector((state: RootState) => state.window);

  const [isShown, setIsShown] = useState(false);

  const handleResize = debounce(() => {

  }, 1000);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.addEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames("main_header", location.pathname === "/zemiverse" ? "main" : "", { search_modal_open: searchModal.open, top: windowSize.top, on: gnb.open, hover: isShown })}
    >

      <div
        className={classNames("hamburger_button", { on: gnb.open })}
        onClick={() => {
          dispatch(gnbSetting(!gnb.open));
          dispatch(mymenuSetting(false));
          // if (windowSize.width < 760) {
          //   if (gnb.open) {
          //     document.body.style.overflow = "unset"
          //   } else {
          //     document.body.style.overflow = "hidden"
          //   }
          // }
        }}
      ></div>

      <div className="main_header_left"
        onMouseEnter={() => { if (window.innerWidth > 1450) setIsShown(true) }}
        onMouseLeave={() => { if (window.innerWidth > 1450) setIsShown(false) }}
      >
        <p
          className="logo"
          onClick={() => {
            dispatch(gnbSetting(false));
            dispatch(mymenuSetting(false));
            navigate(`/zemiverse`);
          }}
        >{t("zemiverse")}</p>
        <MainGnb />
      </div>

      <div className="main_header_right">

        {/* my menu */}
        {login.login && <MyMenu />}

        {/* login_button */}
        {!login.login && (
          <NormalButton
            title={t("header_login")}
            onClick={() => {
              dispatch(gnbSetting(false));
              navigate('/login');
              // dispatch(alertModalSetting(!alert.open));x
            }}
            icon="false"
            buttonStyle="round"
            size="sm"
            hierarchy="secondary_gray"
            buttonClassName='avatar'
          />
        )}

        {/* play_button */}
        <NormalButton
          title={t("header_studio")}
          onClick={() => {
            // navigate('/login');
            // dispatch(alertModalSetting(!alert.open));
            window.open(`http://zemistudio.com/`, `_blank`);
          }}
          icon="trailing"
          buttonStyle="round"
          size="sm"
          hierarchy="secondary_color"
        />

        {/* <LanguageBox /> */}

      </div>
    </div>
  )
}
export default MainHeader230622;