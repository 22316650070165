import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import axios from "axios";

import { PageTop, UpdateList, Loading } from "@components/index";
import { REACT_APP_SERVER_URL } from '../../../../shared/utils';

import { ReactComponent as ChevronLeftIcon } from '@assets/images/layer/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@assets/images/layer/chevron-right.svg';

import { loadingSetting, LoadingState, RootState } from "@store/index";
import { useTranslation } from "react-i18next";


import './update.scss';

export interface YearBoxProps {
  year: number;
  setYear: Function;
  handlePreviousYear: Function;
  handleNextYear: Function;
}

const YearBox = ({ year, setYear, handlePreviousYear, handleNextYear }: YearBoxProps) => {

  return (
    <div className="year_box">
      <div
        className="prev"
        onClick={() => {
          handlePreviousYear();
        }}
      ><ChevronLeftIcon /></div>
      <p className="year">{year}</p>
      <div
        className="next"
        onClick={() => {
          handleNextYear();
        }}
      ><ChevronRightIcon /></div>
    </div>
  );
}

const UpdatePage = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [listData, setListData] = useState([]); // Axios로 받아온 데이터 저장

  const [year, setYear] = useState(0);
  const loading: LoadingState = useSelector((state: RootState) => state.loading);

  const updateApi = async () => {
    dispatch(loadingSetting(true));

    await axios
      .get(`${REACT_APP_SERVER_URL}/board/update?updatetime=${year}`, {
        // headers : {
        //   "Authorization" : "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2ViYWRtaW4iLCJpYXQiOjE2NzE2MDM3NzIsImlzcyI6InVybjpuZXh0b3I6c3lzdGVtIiwiYXVkIjoidXJuOm5leHRvcjphdWRpZW5jZSIsImV4cCI6MTY3MTYxMDk3Mn0.euprWPVBraQtfhGQJStPz3NEnpLsB0JKeP-eBnwej2w"
        // }
      })
      .then(({ data }) => {
        setListData(data.response.data);
      })
      .catch((e) => {
        console.error(e);
      });

    dispatch(loadingSetting(false));
  };

  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    setYear(currentYear);
  }, []);

  const handleNextYear = () => {
    const nextYear = year + 1;
    const date = new Date();
    const currentYear = date.getFullYear();
    setYear(Math.min(nextYear, currentYear));
  };

  const handlePreviousYear = () => {
    const previousYear = year - 1;
    const date = new Date();
    const currentYear = date.getFullYear();
    const minYear = currentYear - 0;
    setYear(Math.max(minYear, previousYear));
  };

  useEffect(() => {
    updateApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <div className="update">
      <div className="update__inner">
        <PageTop title={t("update_title")} />
        <YearBox year={year} setYear={setYear} handleNextYear={handleNextYear} handlePreviousYear={handlePreviousYear} />
        <ul className="board">
          {loading.isLoading ? (
            <Loading />
          ) : listData.length <= 0 || listData === null || listData === undefined ? (
            <div className='no_list'>
              <div className="img"></div>
              <p className="title">{t("nolist_title")}</p>
              <p className="description">{t("nolist_description")}</p>
            </div>
          ) : (
            listData.map((data, idx) => (
              <UpdateList data={data} key={idx} />
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default UpdatePage;