import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // 로컬 스토리지
import storageSession from 'redux-persist/lib/storage/session'; // 세션 스토리지

import path from './path';
import window from './window';
import login from './login';
import modal from './modal';
import alert from './alert';
import searchModal from './searchModal';
import gnb from './gnb';
import mymenu from './mymenu';
import terms from './terms';
import loading from './loading';

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const rootReducer = combineReducers({
  path,
  window,
  login,
  modal,
  alert,
  searchModal,
  gnb,
  mymenu,
  terms,
  loading,
});

// export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export default persistReducer(persistConfig, rootReducer);

export * from './path';
export * from './window';
export * from './login';
export * from './modal';
export * from './alert';
export * from './searchModal';
export * from './gnb';
export * from './mymenu';
export * from './terms';
export * from './loading';