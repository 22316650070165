import { useEffect, useState } from "react";

import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

import classNames from "classnames";
import { useTranslation } from 'react-i18next';

import { RootState, PathState } from "@store/index";
import { ReactComponent as ViewIcon } from '@assets/images/board/view.svg';
import { ReactComponent as HeartIcon } from '@assets/images/board/heart.svg';
import { dateToString } from '@shared/utils/date';

import './boardList.scss';

type BoardListProps = {
  data: any,
  detail?: boolean,
  background?: string,
}

/**
 * 게시판 리스트 (공지사항, 이벤트, 자유게시판, 팁공유게시판, 스크린샷 게시판, 1:1 문의, 디테일 페이지 타이틀에 사용됨)
 * 
 * @data list data (any)
 * @detail 디테일 페이지인지 확인 (boolean)
 * @background 디테일 페이지에서 background가 white여야하므로 추가함 (string)
 */

const BoardList = ({ data, detail, background }: BoardListProps) => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  
  const path: PathState = useSelector((state: RootState) => state.path);

  const [newList, setNewLIst] = useState(false);

  /**
   * 현재 리스트가 생성된지 24시간이 지나기 전이라면 New 표시 띄워줌(디테일이 아닐 경우에만!)
   */
  const NewList = () => {
    var now = new Date();
    var yesterday = new Date(now.setDate(now.getDate() - 1));
    const date: Date = new Date(data.regdate);
    setNewLIst(yesterday.getTime() < date.getTime());
  }

  useEffect(() => {
    if (!detail) {
      NewList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames("board_list", path.nowPath, { fixed: data.notice, detail: detail })}
      onClick={() => {
        if (path.nowPath === "notice" || path.nowPath === "update" || path.nowPath === "event") {
          navigate(`/news/${path.nowPath}/${data._id}`, { state: { _id: data._id } });
        } else if (path.nowPath === "free-board" || path.nowPath === "tip-board" || path.nowPath === "screenshot-board") {
          navigate(`/community/${path.nowPath}/${data._id}`, { state: { _id: data._id } });
        } else if (path.nowPath === "inquiry") {
          navigate(`/customer-service/${path.nowPath}/${data._id}`, { state: { _id: data._id } });
        }
      }}
      style={{ "background": background }}
    >
      {/* image */}
      {(path.nowPath === "event") && <img src={data.thumbnail} alt={data.title} />}
      {(path.nowPath === "screenshot-board") && <img src={data.file} alt={data.title} />}

      {/* category */}
      {(path.nowPath === "notice" || (path.nowPath === "notice" && detail) || path.nowPath === "event" || (path.nowPath === "event" && detail) || (["free-board", "tip-board"].includes(path.nowPath) && data.noti)) && (
        <p className={classNames("category", {
          [t("boardlist_category_1")]: data.category === "공지",
          [t("boardlist_category_2")]: data.category === "점검",
          [t("boardlist_category_3")]: data.category === "상점",
          [t("boardlist_category_4")]: data.status === "진행",
          [t("boardlist_category_5")]: data.status === "종료",
          [t("boardlist_category_6")]: data.status === "예정",
        })}>
          {data.category === "공지" ? t("boardlist_category_1") : data.category === "점검" ? t("boardlist_category_2") : data.category === "상점" ? t("boardlist_category_3") : data.status === "진행" ? t("boardlist_category_4") : data.status === "종료" ? t("boardlist_category_5") : data.status === "예정" ? t("boardlist_category_6") : ""}
        </p>
      )}

      {/* title */}
      <div className="title_box">
        <p className="title">
          {/* comment length */}
          {data.title}&nbsp;
          {(!data.noti && !detail && (path.nowPath === "free-board" || path.nowPath === "tip-board") && (data.comment !== null && data.comment !== undefined && data.comment.length !== 0))
            && <span className="comment">&#91;{data.comment.length}&#93;</span>}
        </p>
        {/* new icon */}
        {(newList && !detail) && <span className="new"></span>}
      </div>

      {/* user */}
      {["free-board", "tip-board", "screenshot-board", "inquiry"].includes(path.nowPath) && (
        <div className="data_box">
          <div className="data_user">
            {data.userImg && <img src={data.userImg} alt={data.user} />}
            {data.userid?.nickname && <p>{data.userid?.nickname}</p>}
            {data.notice && <p>{t("admin")}</p>}
            {/* <p>{data.nickname}</p> */}
          </div>
          {(data.hart !== undefined && data.hart !== null) && (
            <div className="like">
              <HeartIcon />
              <p>{data.hart.length}</p>
            </div>
          )}
          <div className="view">
            <ViewIcon />
            <p>{data.hit}</p>
          </div>
        </div>
      )}

      {/* state */}
      {path.nowPath === "inquiry" && <div className={classNames("state", "category", data.category)}>{data.category}</div>}

      {/* date  */}
      <p className="date">
        {path.nowPath === "event" && <span>{t("event_period")} : </span>}
        {dateToString(data.regdate, 'yyyy.MM.dd', i18n.language)}
      </p>
    </div>
  );
}

export default BoardList;