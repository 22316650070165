const TERMS_SETTINGS = 'terms/TERMS_SETTINGS' as const;


type TermsAction =
  | ReturnType<typeof termsSettings>
;

export const termsSettings = (diff: {terms: boolean, pp: boolean}) => ({
  type: TERMS_SETTINGS,
  payload: diff
});

export type TermsState = {
  terms: boolean,
  pp: boolean
};

const initialState: TermsState = {
  terms: false,
  pp: false
};

function terms(
  state: TermsState = initialState,
  action: TermsAction
): TermsState {
  switch (action.type) {
    case TERMS_SETTINGS:
      // console.log(`terms reudx change [terms: ${action.payload.terms}] && [pp: ${action.payload.pp}]` );
      return { ...state, terms: action.payload.terms, pp: action.payload.pp };
    default:
      return state;
  }
}

export default terms;