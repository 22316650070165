import { useTranslation } from 'react-i18next';

import './club.scss';

const Club = () => {

  const { t } = useTranslation();

  return (
    <div className="container club">

      {/* content1 */}
      <div className="content1">
        <div className="content1__inner">
          <p className="title">{t("club_content1_title")}</p>
          <p className="description">{t("club_content1_description")}</p>
        </div>
      </div>

      {/* content2 */}
      <div className="content2">
        <div className="content2_inner">
          <p className="title">{t("club_content2_title")}</p>
          <p className="description">{t("club_content2_description")}</p>
        </div>
      </div>

      {/* content3 */}
      <div className="content3">
        <div className="content3__inner">
          <div className="box">
            <div className="img"></div>
            <p className="title">{t("club_content3_box1_title")}</p>
            <p className="description">{t("club_content3_box1_description")}</p>
          </div>
          <div className="box">
            <div className="img"></div>
            <p className="title">{t("club_content3_box2_title")}</p>
            <p className="description">{t("club_content3_box2_description")}</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Club;