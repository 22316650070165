const WINDOW_TOP_SETTING = 'window/WINDOW_TOP_SETTING' as const;
const WINDOW_BOTTOM_SETTING = 'window/WINDOW_BOTTOM_SETTING' as const;
const WINDOW_WIDTH_SETTING = 'window/WINDOW_WIDTH_SETTING' as const;

type WindowAction =
  | ReturnType<typeof windowTopSetting>
  | ReturnType<typeof windowBottomSetting>
  | ReturnType<typeof windowWidthSetting>
;

export const windowTopSetting = (diff: boolean) => ({
  type: WINDOW_TOP_SETTING,
  payload: diff
});

export const windowBottomSetting = (diff: boolean) => ({
  type: WINDOW_BOTTOM_SETTING,
  payload: diff
});

export const windowWidthSetting = (diff: number) => ({
  type: WINDOW_WIDTH_SETTING,
  payload: diff
});

export type WindowState = {
  top: boolean;
  bottom: boolean;
  width: number;
};

const initialState: WindowState = {
  top: true,
  bottom: false,
  width: 0,
};

function window(
  state: WindowState = initialState,
  action: WindowAction
): WindowState {
  switch (action.type) {
    case WINDOW_TOP_SETTING:
      return { ...state, top: action.payload };
    case WINDOW_BOTTOM_SETTING:
      return { ...state, bottom: action.payload };
    case WINDOW_WIDTH_SETTING:
      return { ...state, width: action.payload };
    default:
      return state;
  }
}

export default window;