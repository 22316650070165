import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

import './world.scss';

import imgfile1 from '../../../../assets/images/world/content4_top_img1.png';
import imgfile2 from '../../../../assets/images/world/content4_top_img2.png';
import imgfile3 from '../../../../assets/images/world/content4_top_img3.png';
import imgfile4 from '../../../../assets/images/world/content4_top_img4.png';
import imgfile5 from '../../../../assets/images/world/content4_top_img5.png';
import imgfile6 from '../../../../assets/images/world/content4_top_img6.png';
import imgfile7 from '../../../../assets/images/world/content4_top_img7.png';
import imgfile8 from '../../../../assets/images/world/content4_top_img8.png';
import imgfile9 from '../../../../assets/images/world/content4_top_img9.png';
import imgfile10 from '../../../../assets/images/world/content4_top_img10.png';
import imgfile11 from '../../../../assets/images/world/content4_top_img11.png';
import imgfile12 from '../../../../assets/images/world/content4_top_img12.png';
import imgfile13 from '../../../../assets/images/world/content4_top_img13.png';
import imgfile14 from '../../../../assets/images/world/content4_top_img14.png';

declare var require: any;

const World = () => {

  const { t } = useTranslation();

  const content4data = [
    {
      category: t("world_content4_category1"),
      title: t("world_content4_world1")
    },
    {
      category: t("world_content4_category1"),
      title: t("world_content4_world2")
    },
    {
      category: t("world_content4_category2"),
      title: t("world_content4_world3")
    },
    {
      category: t("world_content4_category2"),
      title: t("world_content4_world4")
    },
    {
      category: t("world_content4_category3"),
      title: t("world_content4_world5")
    },
    {
      category: t("world_content4_category3"),
      title: t("world_content4_world6")
    },
    {
      category: t("world_content4_category3"),
      title: t("world_content4_world7")
    },
    {
      category: t("world_content4_category3"),
      title: t("world_content4_world8")
    },
    {
      category: t("world_content4_category3"),
      title: t("world_content4_world9")
    },
    {
      category: "",
      title: ""
    },
  ];

  console.log('imgfile1', imgfile1);

  return (
    <div className="container world">

      {/* content1/2 */}
      {[...Array(2)].map((data: any, idx: number) => (
        <div className={`content${idx + 1}`} key={idx}>
          <div className={`content${idx + 1}__inner`}>
            <p className="title">{t(`world_content${idx + 1}_title`)}</p>
            <p className="description">{t(`world_content${idx + 1}_description`)}</p>
          </div>
        </div>
      ))}

      {/* content3 */}
      <div className="content3">
        <div className="content3__inner">
          {[...Array(2)].map((data: any, idx: number) => (
            <div className="box" key={idx}>
              <div className="img"></div>
              <p className="title">{t(`world_content3_box${idx + 1}_title`)}</p>
              <p className="description">{t(`world_content3_box${idx + 1}_description`)}</p>
            </div>
          ))}
        </div>
      </div>

      {/* content4 */}
      <div className="content4">
        <div className="content4__inner">
          <p className="title">{t("world_content4_title")}</p>
          <div className="top">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              loop
              pagination={{
                clickable: true,
              }}
              navigation
              modules={[Pagination, Navigation]}
            >
              {[...Array(14)].map((data : any, idx: number) => (
                <SwiperSlide>
                  <img src={idx === 0 ? imgfile1 : idx === 1 ? imgfile2 : idx === 2 ? imgfile3 : idx === 3 ? imgfile4 : idx === 4 ? imgfile5 : idx === 5 ? imgfile6 : idx === 6 ? imgfile7 : idx === 7 ? imgfile8 : idx === 8 ? imgfile9 : idx === 9 ? imgfile10 : idx === 10 ? imgfile11 : idx === 11 ? imgfile12 : idx === 12 ? imgfile13 : imgfile14} alt={`충북 화장품 클러스터 이미지 ${idx + 1}`} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="right">
              <div className={classNames("icon", t("world_content4_category3"))}>
                <p className="title">{t("world_content4_category3")}</p>
              </div>
              <p className="title">{t("world_content4_top_title")}</p>
              <p className="sub_title">{t("world_content4_top_subtitle")}</p>
              <p className="description">{t("world_content4_top_description")}</p>
            </div>
          </div>
          <div className="boxes">
            {content4data.map((data: any, idx: number) => (
              <div className="box" key={idx}>
                <div className="img"></div>
                <div className="box__inner">
                  <div className={classNames("icon", data.category)}>
                    <p className="title">{data.category}</p>
                  </div>
                  <p className="title">{data.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default World;