import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { ContentFooter, MainHeader230622, MainGnb, TermsModal, AlertModal, ConfirmModal } from '@components/index';
import { useDispatch, useSelector } from "react-redux";
import { RootState, TermsState, modalOpenSetting, ModalState, AlertModalState } from "@store/index";
import { PpModalPage, TermsModalPage } from "@pages/Login";

const LoginLayout = () => {
  const { i18n } = useTranslation();
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const alert:AlertModalState = useSelector((state: RootState) => state.alert);
  const terms: TermsState = useSelector((state: RootState) => state.terms);
  const dispatch = useDispatch();

  const _modalClose = () => {
    dispatch(modalOpenSetting(false));
  }

  return (
    <div className={classNames(`App`, i18n.language)}>
      {/* Header Components가 들어가는 부분 */}
      <MainHeader230622 />
      {/* Main Content */}
      {/* <MainGnb /> */}
      <Outlet />
      <ContentFooter />
      {(modal.open && modal.type === "terms") && <TermsModal
        close={_modalClose}
        header="이용약관"
        terms={terms.terms}
        pp={terms.pp}
        content={
          <TermsModalPage
            terms={terms.terms}
            pp={terms.pp}
            header="이용약관"
            ok={_modalClose}
          />}
      />}
      {(modal.open && modal.type === "pp") && <TermsModal
        close={_modalClose}
        header="개인정보처리방침"
        terms={terms.terms}
        pp={terms.pp}
        content={
          <PpModalPage
            terms={terms.terms}
            pp={terms.pp}
            header="개인정보처리방침"
            ok={_modalClose}
          />}
      />}
      {alert.open && <AlertModal/>}
      {(modal.open &&( modal.type === "warning" || modal.type === "danger" || modal.type === "success" || modal.type === "info")) && <ConfirmModal title={modal.title} description={modal.description} cancel="취소" type={modal.type} confirmButton={modal.confirmButton} confirmStyle={modal.confirmStyle} alert={modal.alert}/>}
    </div>
  )
};

export default LoginLayout;