import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { RootState, AlertModalState, alertModalSetting } from "@store/index";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/scss/autoplay";

import "./download.scss";
import classNames from "classnames";

import imgfile2 from "../../../../assets/images/download/content2_img2.png";
import imgfile3 from "../../../../assets/images/download/content2_img3.png";
import imgfile4 from "../../../../assets/images/download/content2_img4.png";
import imgfile5 from "../../../../assets/images/download/content2_img5.png";
import imgfile6 from "../../../../assets/images/download/content2_img6.png";

const Download = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  return (
    <div className="container download">
      {/* content1 */}
      <div className="content1">
        <div className="content1__inner">
          <p className="title">{t("download_content1_title")}</p>
          <p className="description">{t("download_content1_description")}</p>
          <div className="boxes">
            {[
              "google",
              // , "apple",
              "window",
            ].map((data: string, idx: number) => (
              <div
                className={classNames("button", data)}
                onClick={() => {
                  if (data === "google") {
                    window.open("https://play.google.com/store/apps/details?id=com.nextor.zemiverse", "_blank");
                  }
                  // else if (data === "apple") {
                  //   dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert") }));
                  //   setTimeout(() => {
                  //     dispatch(alertModalSetting({ open: false, title: "" }));
                  //   }, 2000);
                  // }
                  else if (data === "window") {
                    window.open(
                      // `https://webdata2.s3.ap-northeast-2.amazonaws.com/apk/ZemiverseInstaller.exe`,
                      "https://webdata2.s3.ap-northeast-2.amazonaws.com/apk/ZemiverseInstaller_17.4428.exe",
                      `_blank`
                    );
                  }
                }}
                key={idx}
              ></div>
            ))}
          </div>
        </div>
      </div>

      {/* content2 */}
      <div className="content2">
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          // onSlideChange={(swiper) => {
          //   setActiveSlide(swiper.activeIndex + 1);
          // }}
          initialSlide={0}
          centeredSlides
          pagination={{
            clickable: true,
          }}
          navigation
          rewind
          loop
          // ref={navigationRef}
        >
          <SwiperSlide>
            <ReactPlayer
              url={`https://youtu.be/UbJUrAIBbzc`}
              className="player"
              style={{ objectFit: "cover" }}
              playing
              muted
              loop
              width={3000}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img src={imgfile6} alt="다운로드 스와이퍼 이미지 6" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={imgfile2} alt="다운로드 스와이퍼 이미지 2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={imgfile3} alt="다운로드 스와이퍼 이미지 3" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={imgfile4} alt="다운로드 스와이퍼 이미지 4" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={imgfile5} alt="다운로드 스와이퍼 이미지 5" />
          </SwiperSlide>
        </Swiper>
      </div>

      {/* content3 */}
      <div className="content3">
        <div className="content3__inner__bg">
          {[...Array(9)].map((data: any, idx: number) => (
            <div className={`img${idx + 1}`} key={idx}></div>
          ))}
        </div>
        <div className="content3__inner">
          <div className="top">
            <div className="left">
              <p className="title">{t("download_content3_title")}</p>
              <p className="description">{t("download_content3_description")}</p>
            </div>
            <div className="right"></div>
          </div>

          <div className="bottom">
            <div className="box">
              <p className="title">{t("download_content3_box1_title")}</p>
              <div
                className="logo"
                onClick={() => {
                  // dispatch(connectModalSetting(!modalOpen));
                  window.open(`https://nextor.ai`, `_blank`);
                }}
              ></div>
            </div>
            <div className="box">
              <p className="title">{t("download_content3_box2_title")}</p>
              <p
                className="description"
                onClick={() => {
                  // dispatch(connectModalSetting(!modalOpen));
                  window.open(`https://nextor.ai`, `_blank`);
                }}
              >
                nextor.ai
              </p>
            </div>
            <div className="box">
              <p className="title">{t("download_content3_box3_title")}</p>
              <p className="description">{t("download_content3_box3_description")}</p>
            </div>
            <div className="box">
              <p className="title">{t("download_content3_box4_title")}</p>
              <div className="box__inner">
                {[
                  // "apple",
                  "google",
                  "window",
                ].map((data: any, idx: number) => (
                  <div
                    className={classNames("icon", data)}
                    onClick={() => {
                      if (data === "google") {
                        window.open("https://play.google.com/store/apps/details?id=com.nextor.zemiverse", "_blank");
                      } else if (data === "apple") {
                        dispatch(alertModalSetting({ open: !alert.open, title: t("modal_alert") }));
                        setTimeout(() => {
                          dispatch(alertModalSetting({ open: false, title: "" }));
                        }, 2000);
                      } else if (data === "window") {
                        window.open(
                          // `https://webdata2.s3.ap-northeast-2.amazonaws.com/apk/ZemiverseInstaller.exe`,
                          "https://webdata2.s3.ap-northeast-2.amazonaws.com/apk/ZemiverseInstaller_17.4428.exe",
                          `_blank`
                        );
                      }
                    }}
                    key={idx}
                  ></div>
                ))}
              </div>
            </div>
            <div className="box">
              <p className="title">{t("download_content3_box5_title")}</p>
              <p className="description">{t("download_content3_box5_description")}</p>
            </div>
          </div>
        </div>
      </div>

      {/* content4 */}
      <div className="content4">
        <div className="content4__inner">
          {[...Array(4)].map((a: any, idx: number) => (
            <div className="box" key={idx}>
              <div className="image"></div>
              <div className="box__inner">
                <p className="title">{t(`download_content4_box${idx + 1}_title`)}</p>
                <p className="description">{t(`download_content4_box${idx + 1}_description`)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Download;
