import { useRef } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './loginButton.scss';
import axios from 'axios';
import { encrypto } from '@utils/encrypto';

import { REACT_APP_SERVER_URL } from '@utils/config';

import { loginSetting, profileSetting, LoginState, tokenSetting, RootState, AlertModalState, alertModalSetting } from '@store/index';

const GoogleLoginButton = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login: LoginState = useSelector((state: RootState) => state.login);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      tokenApi(tokenResponse.access_token);
    },
    onError: errorResponse => console.log('error', errorResponse),
  });

  const tokenApi = (accessToken) => {
    const date = new Date();
    let e_data = encrypto(date.getTime(), accessToken);
    axios
      .post(
        `${REACT_APP_SERVER_URL}/user/oauth`, {
        // category: "google",
        access_token: e_data,
        datetime: date
      })
      .then((res: any) => {
        if (res.data.response.status === 200 && res.data.response.data.access_token) {
          sessionStorage.setItem(`accessToken`, res.data.response.data.access_token);
          sessionStorage.setItem(`refreshToken`, res.data.response.data.refreshToken);
          dispatch(tokenSetting({ accessToken: res.data.response.data.access_token }));
          getProfile(res.data.response.data.access_token);
        } else {
          dispatch(alertModalSetting({open : !alert.open, title: '구글 로그인 오류입니다. 개발팀에 문의하세요.'}));
        }
      })
      .catch(error => console.error("error", error));
  };

  const getProfile = async (token) => {
    try {
      const { data } = await axios.post(`${REACT_APP_SERVER_URL}/user/info`, {}, {
        headers: {
          "Authorization": token
        }
      });
      if (data.response.data.active === false) {
        sessionStorage.setItem(`_id`, data.response.data._id); 
        navigate(`/register`);
      } else {
        getProfileExist(data.response.data._id);
      }
      
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
  };

  // 기존 유저일 경우 정보 저장
  const getProfileExist = async (id : string) => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_URL}/user/id/${id}`, {

      });
      if (data.response.data.active === false) {
        sessionStorage.setItem(`_id`, data.response.data._id); 
      } else {
        dispatch(profileSetting({_id: data.response.data._id, name: data.response.data.nickname, picture: data.response.data.profile, category: data.response.data.social_login.from, email: data.response.data.social_login.email}));
        dispatch(loginSetting(true));
        navigate(-1);
        dispatch(alertModalSetting({ open: !alert.open, title: '로그인 되었습니다.' }));
        setTimeout(() => {
          dispatch(alertModalSetting({ open: false, title: "" }));
        }, 2000);
      }
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
  };

  // google 로그인 후 accessToken으로 google 유저 정보 읽어오기
  // const getProfile = (accessToken) => {
  //   axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //       Accept: 'application/json'
  //     }
  //   })
  //     .then((res) => {
  //       sessionStorage.setItem(`email`, res.data.email);
  //       sessionStorage.setItem(`name`, res.data.name);
  //       sessionStorage.setItem(`picture`, res.data.picture);
  //       // console.log(sessionStorage.getItem(`email`));
  //       // console.log(sessionStorage.getItem(`name`));
  //       // console.log(sessionStorage.getItem(`picture`));
  //       dispatch(profileSetting({email: sessionStorage.getItem(`email`), name: sessionStorage.getItem(`name`), picture: sessionStorage.getItem(`picture`)}));
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <div className="login-button google-login"
      onClick={() => googleLogin()}
    >
      <i className="icon google"></i>
      <p>Sign in with Google</p>
    </div>
  );
};

export default GoogleLoginButton;