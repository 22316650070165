import {Outlet} from 'react-router-dom';
import { ContentFooter } from '@components/index';

import './subLayout.scss';

const SubLayout = () => {
  return (
    <div className="content">
      { /* content Header */ }

      {/* content Body */}
      <Outlet />

      {/* content Footer */}
      <ContentFooter/>
    </div>
  )
}

export default SubLayout;