import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from "react-i18next";

import {UseAvatar, CheckBox, InputBox, NormalButton} from '@components/index';
import { RootState, termsSettings, TermsState, modalRegisterSetting, loginSetting, profileSetting } from '@store/index';
import { useInput } from "@hooks/index";
import { REACT_APP_SERVER_URL } from '@utils/config';

import './register.scss';

/**
 * /register - 가입 페이지
 */

const Register = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorLog, setErrorLog] = useState("");
  const nicknameInput = useInput('');

  const [isCheck, setIsCheck] = useState(false);
  const [isAvatar, setIsAvatar] = useState(false);
  
  const terms:TermsState = useSelector((state:RootState) => state.terms);

  const termsLabel = <p onClick={()=>dispatch(modalRegisterSetting({open: true, type: "terms"}))}><span>{t("register_terms")}</span>{t("register_agreed")}</p>;
  const ppLabel = <p onClick={()=>dispatch(modalRegisterSetting({open: true, type: "pp"}))}><span>{t("register_pp")}</span>{t("register_agreed")}</p>;

  const _handleResister = async () => {
    try {
      const { data } = await axios.put(`${REACT_APP_SERVER_URL}/user/id/${sessionStorage.getItem(`_id`)}`, {
        nickname : nicknameInput.value,
        active : true
      }, {
        headers: {
          "Content-Type" : "application/json",
          "Authorization": sessionStorage.getItem(`accessToken`)
        }
      });
      // sessionStorage.setItem(`name`, data.response.data.nickname);
      // sessionStorage.setItem(`_id`, data.response.data._id); 
      // sessionStorage.setItem(`picture`, data.response.data.picture); 
      // sessionStorage.setItem(`category`, data.response.data.from); 
      dispatch(profileSetting({_id: data.response.data._id, name: data.response.data.nickname, picture: data.response.data.profile, category: data.response.data.social_login.from, email: data.response.data.social_login.email}));  
      dispatch(loginSetting(true));
      navigate(`/register/done`);
    } catch (e) {
      console.error(e);
      alert("개발팀에 문의해주세요.");
    }
  };

  const _checkboxHandler = (type: string) => {
    if(type === "terms") {
      terms.terms ? dispatch(termsSettings({...terms, terms: !terms.terms})) : dispatch(modalRegisterSetting({open:true, type:"terms"}));
    }
    else if(type === "pp") {
      terms.pp ? dispatch(termsSettings({...terms, pp: !terms.pp})) :
      dispatch(modalRegisterSetting({open:true, type:"pp"}));
    }
  }

  useEffect(()=> {
    // console.log(nicknameInput.value === "");
    if (terms.terms && terms.pp){
      if (nicknameInput.value !== "") {
        if (nicknameInput.logType === "info") {
          setIsCheck(true);
        }
      } else {
        setIsCheck(false);
      }
    } else {
      setIsCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[terms, nicknameInput.value]);

  useEffect(() => {
    dispatch(termsSettings({terms : false, pp: false}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content register">
      <div className="member">
      <div className="member__base">
        <div className="member__wrapper">
          <div className="member__container">
            <div className="member__content">
              <div className="member__header">
                <p>{t("register_zemvierse")}</p>
                <p>{t("register_almost_done")}</p>
              </div>
              { isAvatar ? <UseAvatar /> : (
                <div className="nickname__container">
                <InputBox 
                  label={t("register_nickname")} 
                  name="nickname"
                  value={nicknameInput.value}
                  onChange={nicknameInput.onChange}
                  placeholder={t("register_nickname")}
                  logMessage={nicknameInput.logMessage}
                  logType={nicknameInput.logType}
                  maxLength={10}
                  // icon={true}
                />
                <div className="nickname__explanation">
                  <p>{t("register_check1")}</p>
                  <p>{t("register_check2")}</p>
                  <p>{t("register_check3")}</p>
                </div>
              </div>
              ) }
            </div>
            <div className="member__footer">
              <CheckBox 
                name="terms-modal"
                onClick={()=>_checkboxHandler("terms")}
                checked={terms.terms}
                label={termsLabel} 
                terms={terms}
              />
              <CheckBox
                name="pp-modal"
                onClick={()=>_checkboxHandler("pp")}
                checked={terms.pp}
                label={ppLabel} 
                terms={terms}
                // size="md"
              />

            </div>
            {errorLog && <p className="member__error">{errorLog}</p> }
          </div>
          <NormalButton title={t("register_continue")} hierarchy="primary" buttonStyle="round" size='md' width={isBrowser ? "160px": "100%"} height="40px" disable={!isCheck} onClick={isCheck ? _handleResister : null}/>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Register;