import { useEffect, useState } from 'react';

import classNames from 'classnames';
import axios from 'axios';

import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import { RootState, PathState, ModalState, modalReportSetting, LoginState, modalSetting, alertModalSetting, AlertModalState, modalOpenSetting } from "@store/index";
import { PageTop, BoardList, NormalButton } from '@components/index';
import { boardTitle } from '@shared/utils';

import { ReactComponent as CornerDownRightIcon } from '@assets/images/layer/corner-down-right.svg';
import { REACT_APP_SERVER_URL } from '../../../shared/utils';

import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/react-editor';

import './detail.scss';
import { useTranslation } from 'react-i18next';

import { getIdPath } from '@utils/getLastPath';

interface CommentBoxProps {
  title: string,
  value: string,
  onChange?: any,
  placeHolder: string,
  limit: number,
  reply?: boolean
}

const CommentBox = ({ title, value, onChange, placeHolder, limit, reply }: CommentBoxProps) => {

  return (
    <div className={classNames("comment__write", { reply: reply })}>
      <p className="comment__write__title">{title}</p>
      <div className="comment__write__box">
        <div className="comment__write__box__inner">
          <textarea
            style={{ resize: "none" }}
            value={value}
            onChange={onChange}
            placeholder={placeHolder}
            maxLength={limit}
          />
          <p className="limit">{value.length}/{limit}</p>
        </div>

        <NormalButton
          title="등록"
          buttonStyle="box"
          size="txl"
          hierarchy="secondary_gray"
        />
      </div>
    </div>
  );
}

interface CommentProps {
  reply?: boolean,
  reReply?: boolean,
  loggedInUser?: boolean
}

const Comment = ({ reply, reReply, loggedInUser }: CommentProps) => {

  const [value, setValue] = useState("");
  const [editValue, setEditValue] = useState("댓글입니다.");
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const login: LoginState = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();

  return (
    <>
      <div className={classNames("comment__board__list", { reply: reply }, { re_reply: reReply }, { logged_in: login.login })}>
        <div className="top">
          <div className="user">
            <CornerDownRightIcon />
            <img className="user__profile" src="" alt="user profile" />
            <p className="user__id">유저 아이디</p>
          </div>
          <div className="box">
            <p className="date">2023-02-23</p>
            <NormalButton
              title="신고"
              hierarchy="tertiary_gray"
              icon="leading"
              buttonStyle="round"
              size="xs"
              onClick={() => {
                // dispatch(modalReportSetting({ open: !modal.open, type: "report" }))
              }}
            />
          </div>
        </div>
        {edit ? <textarea
          style={{ resize: "none" }}
          value={editValue}
          onChange={(e: any) => {
            setEditValue(e.target.value);
          }}
          placeholder={"저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."}
          maxLength={200}
        /> : <p className="description">
          {editValue}
        </p>}
        <div className="bottom">
          <div className="bottom__right">
            {!reReply && <NormalButton
              title={open ? "취소" : "답글작성"}
              buttonStyle='round'
              size='xs'
              hierarchy="primary_line"
              onClick={() => {
                setOpen(!open);
              }}
            />}
            <NormalButton
              title={edit ? "저장" : "수정"}
              buttonStyle='round'
              size='xs'
              hierarchy="secondary_color"
              onClick={() => {
                setEdit(!edit);
              }}
            />
          </div>

          <NormalButton
            title="삭제"
            buttonStyle='round'
            size='xs'
            hierarchy="secondary_gray"
            onClick={() => {
              // dispatch(modalSetting({ open: !modal.open, type: "danger", title: "삭제하기", description: "정말로 글을 삭제하시겠습니까?", confirmButton: "삭제하기", confirmStyle: "red" }));
            }}
          />
        </div>
      </div>
      {open && <CommentBox
        title="답글 작성"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
        }}
        placeHolder="저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."
        limit={200}
        reply={reply}
      />}
    </>
  );
}

interface CommentListProps {
  loggedInUser: boolean,
}

const CommentList = ({ loggedInUser }: CommentListProps) => {
  return (
    <>
      {/* 댓글 */}
      <Comment loggedInUser={loggedInUser} />
      {/* 대댓글 .map 돌리기 */}
      <Comment reply loggedInUser={loggedInUser} />
      {/* 대대댓글 .map 돌리기 */}
      <Comment reReply loggedInUser={loggedInUser} />
    </>
  );
}

const Detail = () => {

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");
  const [likeOn, setLikeOn] = useState(false);
  const [like, setLike] = useState(0);
  const [date, setDate] = useState<Date | string>(new Date());
  const [pageId, setPageId] = useState("");
  const [detailData, setDetailData] = useState<any>([]);

  const path: PathState = useSelector((state: RootState) => state.path);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const login: LoginState = useSelector((state: RootState) => state.login);

  let boardType = (["notice", "update", "event"].includes(path.nowPath)) ? `board` : (["free-board", "tip-board", "screenshot-board"].includes(path.nowPath)) ? `community` : ``;
  let pathType = (["notice", "update", "event"].includes(path.nowPath)) ? path.nowPath : (path.nowPath === "free-board") ? `forum` : (path.nowPath === "tip-board") ? `tip` : (path.nowPath === "screenshot-board") ? `screenshot` : ``;

  const fetchData = async (url: string) => {
    try {
      const { data } = await axios.get(url);
      // setDetailData(data.response.data);
      // if(path.nowPath === "tip-board" || path.nowPath === "-board") {
      //   setDetailData(data.data);
      // } else {
      setDetailData(data.response.data);
      if (["free-board", "tip-board", "screenshot-board"].includes(path.nowPath)) {
        setLike(data.response.data.hart?.length);
        setLikeOn(data.response.data.like);
      }
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const DetailApi = () => {
    if (boardType && pathType && pageId) {
      fetchData(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}/${pageId}`);
    }
  };

  const DeleteApi = async () => {
    await axios
      .delete(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}/${pageId}`, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": sessionStorage.getItem(`accessToken`)
        }
      })
      .then(({ data }) => {
        dispatch(alertModalSetting({ open: !alert.open, title: '게시물이 삭제 되었습니다.' }));
        setTimeout(() => {
          dispatch(alertModalSetting({ open: false, title: "" }));
        }, 2000);
        navigate(-1);
      })
      .catch((e) => {
        console.error(e);
        // alert("개발팀에 문의해주세요.");
      });
  };

  // 좋아요 눌렀을 때 API
  const LikeApi = async () => {

    await axios
      .post(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}/${pageId}/like/${login._id}`, {}, {
        headers: {
          // "Content-Type": "application/json",
          "Authorization": sessionStorage.getItem(`accessToken`)
        }
      })
      .then(({ data }) => {
        DetailApi();
      })
      .catch((e) => {
        console.error(e);
        // alert("개발팀에 문의해주세요.");
      });
  };

  // 신고 confirm 눌렀을 때
  const reportConfirm = async () => {
    await axios
      .get(`${REACT_APP_SERVER_URL}/complaint`, {
        // header
      })
      .then(({ data }) => {
        if (path.nowPath === "notice" || path.nowPath === "update" || path.nowPath === "event") {
          setDetailData(data.response.data);
        } else {
          if(path.nowPath === "free-board" || path.nowPath === "screenshot-board" || path.nowPath === "tip-board") {
            setDetailData(data.response.data);
          } else {
            setDetailData(data.data);
          }
          
        }
      })
      .catch((e) => {
        console.error(e);
        // alert("개발팀에 문의해주세요.");
      });
  }

  useEffect(() => {
    setPageId(getIdPath(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    DetailApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  return (
    <div className={classNames("detail", path.nowPath)}>
      <div className="detail__inner">

        {/* page title */}
        <PageTop title={boardTitle(path.nowPath)} />

        {/* title */}
        {detailData && <BoardList data={detailData} key={detailData._id} background="white" detail />}

        {/* board */}
        <div className="board border">

          {/* 여기에 에디터에서 받아온 내용 껴넣기 */}
          <div className="board__inner">
            {/* {detailData?.thumbnail && <img src={`${detailData.thumbnail}`} alt={""} />} */}
            {/* {(detailData?.file && (detailData?.file !== false && detailData?.file !== "false")) && <img src={`${detailData.file}`} alt={detailData.title} />} */}
            {detailData?.content && <Viewer initialValue={detailData.content || ''} // 유튜브 삽입 및 미리보기 를 위한 설정(iframe)
              customHTMLRenderer={{
                htmlBlock: {
                  iframe(node) {
                    return [
                      {
                        type: "openTag",
                        tagName: "iframe",
                        outerNewLine: true,
                        attributes: node.attrs,
                      },
                      { type: "html", content: node.childrenHTML },
                      { type: "closeTag", tagName: "iframe", outerNewLine: false }
                    ];
                  }
                }
              }}
            />}
          </div>

          {/* //여기에 에디터에서 받아온 내용 껴넣기 */}

          {/* board 하단 like/report 버튼 */}
          {(["free-board", "tip-board", "screenshot-board"].includes(path.nowPath) && login.login && !detailData.notice) && (
            <div className="board__buttons">
              <NormalButton
                title={String(detailData.hart?.length)}
                buttonStyle="round"
                size="md"
                hierarchy="secondary_gray"
                icon="leading"
                onClick={() => {
                  LikeApi();
                }}
                buttonClassName={classNames('like',{ on: likeOn })}
              />
              {/* {(detailData.userid?._id !== login._id) && <NormalButton
                title="신고"
                buttonStyle="round"
                size="xs"
                hierarchy="tertiary_gray"
                icon="leading"
                onClick={() => {
                  dispatch(modalReportSetting({ open: !modal.open, type: "report", userId: detailData.userid?.nickname, date: new Date(), onConfirm: reportConfirm }));
                }}
                buttonClassName={classNames('report')}
              />} */}
            </div>
          )}

        </div>

        {/* comment */}
        {/* {(path.nowPath === "free-board" || path.nowPath === "tip-board" || path.nowPath === "screenshot-board" || path.nowPath === "inquiry") && (
          <div className="comment">

            <CommentBox
              title={path.nowPath === "inquiry" ? "문의 댓글" : "댓글 " + ((detailData.comment === null || detailData.comment === undefined) ? "" : detailData.comment)}
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
              }}
              placeHolder="저작권 등 다른 사람의 권리를 침해하거나 명예를 훼손하는 게시물은 이용약관 및 관련 법률에 의해 제재를 받을 수 있습니다.&#13;&#10;건전한 토론 문화와 양질의 댓글 문화를 위해, 타인에게 불쾌감을 주는 욕설 또는 특정 계층/민족, 종교 등을 비하하는 단어들은 표시가 제한 됩니다."
              limit={200}
            />

            <div className="comment__board">
              <CommentList loggedInUser={login.login} />
            </div>
          </div>
        )} */}

        {/* detail bottom */}
        <div className="detail__inner__bottom">
          {detailData.userid?._id === login._id && (
            <NormalButton
              title={t("delete")}
              buttonStyle='box'
              size='md'
              hierarchy="secondary_gray"
              onClick={() => {
                dispatch(modalSetting({ open: !modal.open, type: "danger", title: "삭제하기", description: "정말로 글을 삭제하시겠습니까?", confirmButton: "삭제하기", confirmStyle: "red", onConfirm: DeleteApi, alert: false }));
              }}
            />
          )}

          <div className="right">
            {detailData?.userid?._id === login._id && (
              <NormalButton
                title={t("edit")}
                buttonStyle='box'
                size='md'
                hierarchy="primary_line"
                onClick={() => {
                  navigate(`/community/${path.nowPath}/write`, { state: { _id: detailData._id } });
                }}
              />
            )}
            <NormalButton
              title={t("list")}
              buttonStyle='box'
              size='md'
              hierarchy="primary"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default Detail;