import { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { useTranslation } from 'react-i18next';

import { Editor } from '@toast-ui/react-editor';

import { PageTop, InputBox, EditorComponent, NormalButton } from "@components/index";
import { RootState, ModalState, modalSetting, PathState, loadingSetting, LoginState, alertModalSetting, AlertModalState, modalOpenSetting } from '@store/index';
import { boardTitle } from '@shared/utils';

import { REACT_APP_SERVER_URL } from '../../../shared/utils';

const Write = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const editorRef = useRef<Editor>(null);
  
  const [title, setTitle] = useState('');
  const [detailData, setDetailData] = useState<any>([]);
  
  //파일 미리볼 url을 저장해줄 state
  const [fileImage, setFileImage] = useState("");
  const [file, setFile] = useState<Blob | string>("");
  
  const path: PathState = useSelector((state: RootState) => state.path);
  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  const login: LoginState = useSelector((state: RootState) => state.login);

  let boardType = (["notice", "update", "event"].includes(path.nowPath)) ? `board` : (["free-board", "tip-board", "screenshot-board"].includes(path.nowPath)) ? `community` : ``;
  let pathType = (["notice", "update", "event"].includes(path.nowPath)) ? path.nowPath : (path.nowPath === "free-board") ? `forum` : (path.nowPath === "tip-board") ? `tip` : (path.nowPath === "screenshot-board") ? `screenshot` : ``;

  // 상세 내용 가져오기
  const DetailApi = async () => {
    await axios
      .get(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}/${location.state._id}`, {
        // header
      })
      .then(({ data }) => {
        if (path.nowPath === "notice" || path.nowPath === "update" || path.nowPath === "event") {
          setDetailData(data.response.data);
        } else {
          if(path.nowPath === "free-board" || path.nowPath === "screenshot-board" || path.nowPath === "tip-board") {
            setDetailData(data.response.data);
          } else {
            setDetailData(data.data);
          }
          
        }
      })
      .catch((e) => {
        console.error(e);
        // alert("개발팀에 문의해주세요.");
      });
  };

  // post 글 작성시 API
  const communityPostApi = async () => {
    dispatch(loadingSetting(false));
    await axios
      .post(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}`, {
        userid: login._id,
        name: login.name,
        language: "ko",
        title: title,
        content: editorRef.current?.getInstance().getHTML(),

        // file
        file: (path.nowPath === "screenshot-board") && file,
      }, {
        headers: {
          "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          "Authorization": sessionStorage.getItem(`accessToken`)
        }
      })
      .then(({ data }) => {
        dispatch(alertModalSetting({ open: !alert.open, title: '게시물이 등록 되었습니다.' }));
        setTimeout(() => {
          dispatch(alertModalSetting({ open: false, title: "" }));
        }, 2000);
        navigate(-1);
      })
      .catch((e) => {
        console.error(e);
        // alert("개발팀에 문의해주세요.");
      });
  };

  // put 글 수정시 API
  const communityPutApi = async () => {
    dispatch(loadingSetting(true));
    await axios
      .put(`${REACT_APP_SERVER_URL}/${boardType}/${pathType}/${location.state?._id}`, {
        userid: login._id,
        name: login.name,
        language: "ko",
        title: title,
        content: editorRef.current?.getInstance().getHTML(),

        // file
        file: ((path.nowPath === "screenshot-board") && file) ? file : null,
      }, {
        headers: {
          "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          "Authorization": sessionStorage.getItem(`accessToken`)
        }
      })
      .then(({ data }) => {
        dispatch(alertModalSetting({open : !alert.open, title: '게시물이 수정 되었습니다.'}));
        setTimeout(() => {
          dispatch(alertModalSetting({ open: false, title: "" }));
        }, 2000);
        navigate(-1);
      })
      .catch((e) => {
        console.error(e);
      });
    dispatch(loadingSetting(false));
  };

  const EmptyValueWarning = () => {
    const isTitleEmpty = !title;
    const isFileEmpty = !file;
    // const isEditorEmpty = !editorRef.current?.getInstance().getMarkdown() || editorRef.current?.getInstance().getMarkdown().length < 100;
    const isCreating = !location.state;

    if (isTitleEmpty) {
      dispatch(modalSetting({open : true, type: "danger", title: t("write_empty_title"), description: "", confirmButton: "확인", confirmStyle: "red", onConfirm: () => {dispatch(modalOpenSetting(false));}, alert: true}));
    // } else if (isEditorEmpty) {
    //   window.alert(t("write_empty_contents"));
    } else if (isFileEmpty) {
      if (path.nowPath === "screenshot-board") {
        dispatch(alertModalSetting({open : !alert.open, title: t("write_empty_image")}));
      } else if (path.nowPath !== "screenshot-board") {
        if (isCreating) {
          communityPostApi();
        } else {
          communityPutApi();
        }
      }
    } else {
      if (isCreating) {
        communityPostApi();
      } else {
        communityPutApi();
      }
    }
  };

  // 이미지 파일 저장
  const saveFileImage = (e: any) => {
    setFileImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
    e.target.value = '';
  };

  // 이미지 파일 삭제
  const deleteFileImage = () => {
    URL.revokeObjectURL(fileImage);
    setFileImage("");
  };

  useEffect(() => {
    // 1. DB에서 가져온 HTML이라고 가정
    const htmlString = '';
    // 2. Editor DOM 내용에 HTML 주입
    editorRef.current?.getInstance().setHTML(htmlString);
  }, []);

  

  useEffect(() => {
    if (modal.isOk === true) {
      // _submitController();
    }
  }, [modal]);

  useEffect(() => {
    if (location.state?._id) {
      DetailApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (detailData) {
      setTitle((detailData.title) ? detailData.title : "");
      editorRef.current?.getInstance().setHTML(detailData.content);
      if (path.nowPath === "screenshot-board") {
        if (detailData.file !== undefined || detailData.file !== null || detailData.file !== "") {
          setFileImage(`${detailData.file}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <div className="write">
      <div className="write__inner">
        <PageTop title={boardTitle(path.nowPath)} />
      </div>
      <div className='write__body'>
        <div className='write__body--top'>
          <InputBox
            name="title"
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTitle(e.target.value);
            }}
            placeholder={t("write_title_insert")}
            maxLength={40}
          // icon={true}
          />
        </div>
        <div className='write__body--editor'>
          <EditorComponent editorRef={editorRef} />
        </div>
        {(path.nowPath === "screenshot-board") && (
            <div className="write__body--bottom">

              <div className="top">
                <p className="title">
                  {t("write_thumnail")}
                  {t("")}
                  <span>{t("write_thumnail_content")}</span>
                </p>

                <div className="button--upload">
                  <label className="button" htmlFor="file">{t("write_select_image")}</label>
                  <input className="hide" type="file" accept="image/*" onChange={saveFileImage} id="file"/>
                </div>
              </div>

              {(fileImage !== undefined && fileImage !== null && fileImage !== `undefined` && fileImage !== "") && (
                <div className="img--box">
                  <img
                    alt="uploadImg"
                    src={fileImage}
                  />
                  <div
                    className="button--file--delete"
                    onClick={() => {
                      deleteFileImage();
                    }}
                  ></div>
                </div>
              )}
            </div>
          )}
      </div>
      <div className='write__bottom'>
        <NormalButton title="취소" hierarchy="secondary_gray" onClick={() => {
          navigate(-1);
        }} buttonStyle="box" size='md' />
        <NormalButton
          title={location?.state ? t("write_edit") : t("write_register")}
          hierarchy="primary"
          buttonStyle="box"
          size='md'
          onClick={() => {
            if (location?.state) {
              dispatch(modalSetting({ open: !modal.open, type: "success", title: t("write_edit_title"), description: t("write_edit_question"), confirmButton: t("write_edit_title"), confirmStyle: "primary", onConfirm: EmptyValueWarning, alert: false }));
            } else {
              dispatch(modalSetting({ open: !modal.open, type: "success", title: t("write_register_title"), description:  t("write_register_question"), confirmButton:  t("write_register_title"), confirmStyle: "primary", onConfirm: EmptyValueWarning, alert: false }));
            }
          }}
        />
      </div>
    </div>
  );
};

export default Write;