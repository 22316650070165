
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { NormalButton, SelectBox, PageTop } from '@components/index';
import { RootState, ModalState, modalReportSetting, LoginState, modalSetting, modalOpenSetting } from "@store/index";
import { updateDateToString } from '@utils/updateDate';
import useSelect from '@hooks/useSelect';

import '../modal.scss';

const ReportModal = () => {

  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  const CategoryList = [
    {id : 1, title : t("modal_report_category1")},
    {id : 2, title : t("modal_report_category2")},
    {id : 3, title : t("modal_report_category3")},
    {id : 4, title : t("modal_report_category4")},
  ];

  const selectData = useSelect(CategoryList[0]);

  const modal: ModalState = useSelector((state: RootState) => state.modal);
  const login: LoginState = useSelector((state: RootState) => state.login);

  const handleConfirmClick = () => {
    modal.onConfirm();
    dispatch(modalOpenSetting(!modal.open));
  };

  return(
    <div className="dim">
      <div className="modal report">
        <div 
          className="close_button"
          onClick={() => {
            dispatch(modalOpenSetting(!modal.open))
          }}
        ></div>
        <PageTop title={t('modal_report_title')} />
        <p className="description">{t("modal_report_description")}</p>
        <table>
          <tbody>
            <tr>
              <th>{t("modal_report_box1_title")}</th>
              <td>{login.name}</td>
            </tr>
            <tr>
              <th>{t("modal_report_box2_title")}</th>
              <td>{updateDateToString(modal.date, 'yyyy.MM.dd hh:mm:ss', i18n.language)}</td>
            </tr>
            <tr>
              <th>{t("modal_report_box3_title")}</th>
              <td>{modal.userId}</td>
            </tr>
            <tr>
              <th>{t("modal_report_box4_title")}</th>
              <td>
              <SelectBox 
                name="category"
                data={CategoryList}
                selectData={selectData}
                classification
              />
              </td>
            </tr>
            <tr>
              <th>{t("modal_report_box5_title")}</th>
              <td>
                <textarea
                  placeholder={t("modal_report_box5_description")}
                  style={{ resize: "none" }}
                  maxLength={50}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_box">
          <NormalButton 
            title={t("modal_report_button1")}
            size="xl"
            hierarchy="primary"
            buttonStyle="box"
            onClick={() => {
              dispatch(modalSetting({open: modal.open, type: "warning", title: t("modal_report_button1") , description: t("modal_report_button1_confirm"), confirmButton: t("modal_report_button1"), confirmStyle: "primary", onConfirm: handleConfirmClick, alert: false }))
            }}
          />
          <NormalButton 
            title={t("modal_report_button2")}
            size="xl"
            hierarchy="secondary_gray"
            buttonStyle="box"
            onClick={() => {
              dispatch(modalReportSetting({ open: !modal.open, type: "", userId: "", date: "", onConfirm: () => {} }));
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ReportModal;