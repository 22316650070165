import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import axios from "axios";

import { CategoryBox, BoardList, PageTop, InputBox, SelectBox, NormalButton, Loading, SearchMobileModal } from "@components/index";
import { LoadingState, RootState, loadingSetting, SearchModalState, searchModalSetting } from "@store/index";
import useSelect from '@hooks/useSelect';
import { REACT_APP_SERVER_URL } from '../../../../shared/utils';

import { ReactComponent as SearchIcon } from '@assets/images/layer/search.svg';
import './notice.scss';
import { useTranslation } from "react-i18next";

interface keyInterface {
  id: number
  text: string
}

const NoticePage = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch();

  const CategoryList = [
    { id: 0, title: t("notice_category_2_1") },
    { id: 1, title: t("notice_category_2_2") },
    { id: 2, title: t("notice_category_2_3") },
  ];

  const CategoryListData = [
    { id: 0, title: t("notice_category_1_1") },
    { id: 1, title: t("notice_category_1_2") },
    { id: 2, title: t("notice_category_1_3") },
    { id: 3, title: t("notice_category_1_4") }
  ];

  const [listData, setListData] = useState([]); // Axios로 받아온 데이터 저장
  const [currentList, setCurrentList] = useState([]); // 현제 페이지의 리스트
  const [page, setPage] = useState(1); // 현재 페이지
  const handlePageChange = (page: number) => { setPage(page) };
  const [listPerPage] = useState(12); // 페이지 당 리스트 개수
  const indexOfLastList = page * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const [category, setCategory] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const selectData = useSelect(CategoryList[0]);

  const loading: LoadingState = useSelector((state: RootState) => state.loading);
  const searchModal: SearchModalState = useSelector((state: RootState) => state.searchModal);

  const getNoticeApiUrl = (category: number, searchValue?: string) => {
    let url = `${REACT_APP_SERVER_URL}/board/notice`;
    if (category !== 0) {
      url += `?category=${category}`;
    }
    const searchFields: any = {
      0: "title",
      1: "content",
      2: "title_content"
    };

    const searchField = searchFields[selectData.value.id];

    if (searchValue) {
      url += `${category !== 0 ? "&" : "?"}${searchField}=${searchValue}`;
    }
    return url;
  };

  const fetchNoticeApi = async (url: string) => {
    dispatch(loadingSetting(true));
    try {
      const { data } = await axios.get(url, {});
      setListData(data.response.data);
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
    dispatch(loadingSetting(false));
  };

  const NoticeAllApi = async () => {
    const url = getNoticeApiUrl(0);
    fetchNoticeApi(url);
  };

  const NoticeApi = async () => {
    const url = getNoticeApiUrl(category);
    fetchNoticeApi(url);
  };

  const NoticeAllSearchApi = async () => {
    const url = getNoticeApiUrl(0, searchValue);
    fetchNoticeApi(url);
  };

  const NoticeSearchApi = async () => {
    const url = getNoticeApiUrl(category, searchValue);
    fetchNoticeApi(url);
  };

  useEffect(() => {
    setSearchValue("");
    if (category === 0) {
      NoticeAllApi();
    } else {
      NoticeApi();
    }
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddKeyword(searchValue);
      if (searchValue !== "" && searchModal.open) {
        dispatch(searchModalSetting(!searchModal.open));
      }
      if (category === 0) {
        NoticeAllSearchApi();
      } else {
        NoticeSearchApi();
      }
      setPage(1);
    }
  };

  useEffect(() => {
    if (listData === null || listData === undefined) {
    } else {
      setCurrentList(listData.slice(indexOfFirstList, indexOfLastList));
    }
  }, [indexOfFirstList, indexOfLastList, page, listData]);

  const [keywords, setKeywords] = useState<keyInterface[]>([]);

  // 검색어 추가
  const handleAddKeyword = (text: string) => {
    if(text === null || text === undefined || text === "") {

    } else {
      const newKeyword = {
        id: Date.now(),
        text: text,
      };
      setKeywords([newKeyword, ...keywords]);
    }
  };

  // 단일 검색어 삭제
  const handleRemoveKeyword = (id: number) => {
    
    const nextKeyword = keywords.filter((keyword) => {
      return keyword.id !== id;
    });
    setKeywords(nextKeyword);
  };

  //검색어 전체 삭제
  const handleClearKeywords = () => {
    setKeywords([]);
  };

  // ① window 즉, 브라우저가 모두 렌더링된 상태에서 해당 함수를 실행할 수 있도록 작업
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const result = localStorage.getItem('keywords') || '[]'
      setKeywords(JSON.parse(result))
    };
  }, []);

  // ② keywords 객체를 의존하여, 변경될 경우 새롭게 localStroage의 아이템 'keywords'를 세팅한다
  useEffect(() => {
    localStorage.setItem('keywords', JSON.stringify(keywords));
  }, [keywords]);

  return (
    <>
      <div className="notice">
        <div className="notice__inner">
          <PageTop title={t("notice_title")} />
          <div className="board">
            <div className="board__top">
              <CategoryBox data={CategoryListData} category={category} setCategory={setCategory} />
              <div className="right">
                <SelectBox
                  name="category"
                  data={CategoryList}
                  selectData={selectData}
                  classification
                />
                <InputBox
                  value={searchValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder={t("notice_placeholder")}
                  onKeyPress={handleOnKeyPress}
                  icon
                />
                <SearchIcon 
                  onClick={() => {
                    dispatch(searchModalSetting(!searchModal.open));
                  }}
                />
              </div>
            </div>
            <div className="board__body">
              {loading.isLoading ? (
                <Loading />
              ) : currentList.length <= 0 || currentList === null || currentList === undefined ? (
                <div className='no_list'>
                  <div className="img"></div>
                  <p className="title">{t("nolist_title")}</p>
                  <p className="description">{t("nolist_description")}</p>
                </div>
              ) : (
                currentList.map((data, idx) => {
                  return (
                    <BoardList data={data} key={idx} />
                  );
                })
              )}
            </div>
            <div className="board__bottom">
              {currentList.length <= 0 || currentList === null || currentList === undefined ? (
                null
              ) : (
                <>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={listPerPage}
                    totalItemsCount={listData === null || listData === undefined ? 0 : listData.length}
                    pageRangeDisplayed={10}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={handlePageChange}
                    innerClass="board__pagination"
                    activeClass="selected"
                    itemClassFirst="normal_button box sm secondary_gray"
                    itemClassPrev="normal_button box sm secondary_gray"
                    itemClassNext="normal_button box sm secondary_gray"
                    itemClassLast="normal_button box sm secondary_gray"
                  />
                  <div className="mobile_pagination">
                    <NormalButton
                      title="<"
                      size="sm"
                      hierarchy="secondary_gray"
                      buttonStyle="box"
                      onClick={() => {
                        if (page > 1) {
                          handlePageChange(page - 1);
                        }
                      }}
                    />
                    {listData === null || listData === undefined || listData.length <= 0 ? (
                      null
                    ) : (
                      <p className="page">Page {page} of {(listData.length / listPerPage) < 1 ? "1" : Math.ceil(listData.length / listPerPage)}</p>
                    )}

                    <NormalButton
                      title=">"
                      size="sm"
                      hierarchy="secondary_gray"
                      buttonStyle="box"
                      onClick={() => {
                        if (page < Math.ceil(listData.length / listPerPage)) {
                          handlePageChange(page + 1);
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(searchModal.open)
        && <SearchMobileModal
          searchValueList={keywords}
          searchValue={searchValue}
          setSearchValue={setSearchValue} 
          handleOnKeyPress={handleOnKeyPress}
          deleteAllSearchValue={handleClearKeywords}
          deleteSearchValue={handleRemoveKeyword}
        />
      }
    </>
  );
};

export default NoticePage;