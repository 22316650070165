import { PageTop, NormalButton } from "@components/index";
import { useTranslation } from "react-i18next";

const InquiryTemporaryPage = () => {

  const { t } = useTranslation();

  return (
    <div className="inquiry">
      <div className="inquiry__inner">
        <PageTop title="1:1문의" />
        <div className="temporary">
          <div className="center">
            <div className="image"></div>
            <p className="description">
              {t("inquiry_t_description1")}<span className="strong">{t("inquiry_t_description2")}</span>{t("inquiry_t_description3")}
              <a href="mailto:support@nextor.ai">{t("inquiry_t_description4")}</a>{t("inquiry_t_description5")}
            </p>
            <NormalButton 
              size="xl"
              buttonStyle="box"
              title={t("inquiry_t_button")}
              hierarchy="primary"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                window.location.href = "mailto:support@nextor.ai";
                e.preventDefault();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InquiryTemporaryPage;