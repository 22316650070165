import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { REACT_APP_CLIENT_ID } from '@shared/utils/config';
// pages
import App from './App';
// router
import { BrowserRouter } from 'react-router-dom';
//seo 
import { HelmetProvider } from 'react-helmet-async';
// redux
import rootReducer from './store/index';
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';
// css
import '@assets/css/reset.css';

const store = createStore(rootReducer);

const element = (
  <Provider store={store}>
    <GoogleOAuthProvider clientId={REACT_APP_CLIENT_ID}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(element);