import classNames from 'classnames';

import {ReactComponent as SearchIcon} from '@assets/images/layer/search.svg'

import './inputbox.scss';

export interface InputBoxProps {
  name?: string,
  value: string | undefined,
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  placeholder?: string,
  label?: string,
  icon?: boolean,
  logMessage?: string,
  logType?: string,
  disabled?: boolean,
  maxLength?: number
}

/**
 * @param name input에 들어갈 이름, label과 연결하기 위해서 사용
 * @param value 현재 설정되어 있는 기본 값
 * @param onChange onChnage함수로 동작하게 될 함수
 * @param placeholder placeholder값
 * @param icon icon의 여부
 * 
 * input 아래에 경고 메시지가 생겨야 할 경우
 * @param logMessage 경고 메시지 text
 * @param logType 경고의 종류(info, error, warn)
 */
const InputBox = ({name, value, onChange, onKeyPress, placeholder, label, icon, logMessage, logType, disabled, maxLength} : InputBoxProps) => {

  const searchInput = () => {
    return (
      <div className="search_input">
        <SearchIcon />
        <input       
        type="text" 
        name={name} 
        value={value} 
        onChange={onChange} 
        placeholder={placeholder} 
        onKeyPress={onKeyPress}
        disabled={disabled}
        maxLength={maxLength}
        />
      </div>
    )
  }

  const defaultInput = () => {
    return (
      <input 
      type="text" 
      name={name} 
      value={value} 
      onChange={onChange} 
      placeholder={placeholder}
      onKeyPress={onKeyPress}
      disabled={disabled}
      maxLength={maxLength}
      />
    )
  }

  return (
    <div className="input_wrapper">
      {label && <label htmlFor={name}>{label}</label>}
      {icon ? searchInput() : defaultInput()}
      {logMessage && <p className={classNames(`log`, {"info": logType === "info", "error": logType === "error", "warn": logType === "warn"})}>{logMessage}</p>}
    </div>
  );
};

export default InputBox;