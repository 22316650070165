import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { RootState, GNBState, AlertModalState, gnbSetting, alertModalSetting, SearchModalState, LoginState, loadingSetting, loginSetting, tokenSetting, profileSetting, mymenuSetting, MYMENUState } from "@store/index";
import classNames from 'classnames';

const MyMenu = () => {

  const [myMenuOpen, setMyMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login: LoginState = useSelector((state: RootState) => state.login);
  const gnb: GNBState = useSelector((state: RootState) => state.gnb);
  const mymenu: MYMENUState = useSelector((state: RootState) => state.mymenu);
  const alert: AlertModalState = useSelector((state: RootState) => state.alert);
  

  return (
    <div className="avatar">
      {login.picture && <img className="profile" src={login.picture} alt='' onClick={() => {
        // setMyMenuOpen(!myMenuOpen);
        dispatch(mymenuSetting(!mymenu.open));
        dispatch(gnbSetting(false));
      }} />}
      {!login.picture && <div className="profile none" onClick={() => {
        // setMyMenuOpen(!myMenuOpen);
        dispatch(mymenuSetting(!mymenu.open));
      }} />}
      <div
        className={classNames("my_menu_box", { on: mymenu.open })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="my_menu_top">
          {/* <div className="avatar"></div> */}
          {login.picture && <img className="avatar" src={login.picture} alt="profile" />}
          {!login.picture && <div className="avatar none" />}
          
          <div className="my_menu_top_right">
            <p className="nickname">{login.name}</p>
            <p className="email">{login.email}</p>
          </div>
        </div>
        <ul className="menu">
          <li
            onClick={() => {
              // dispatch(alertModalSetting(!alert.open));
              navigate(`/mypage`);
              // setMyMenuOpen(!myMenuOpen);
              dispatch(mymenuSetting(!mymenu.open));
            }}
          >마이페이지</li>
          {/* <li
            onClick={() => {
              navigate(`/studio/my-contents`);
              setMyMenuOpen(!myMenuOpen);
            }}
          >My Contents</li>
          <li
            onClick={() => {
              dispatch(alertModalSetting(!alert.open));
              setMyMenuOpen(!myMenuOpen);
            }}
          >구매내역</li>
          <li
            onClick={() => {
              dispatch(alertModalSetting(!alert.open));
              setMyMenuOpen(!myMenuOpen);
            }}
          >결제내역</li> */}
          <li
            onClick={() => {
              navigate(`/customer-service/inquiry`);
              // setMyMenuOpen(!myMenuOpen);
              dispatch(mymenuSetting(!mymenu.open));
            }}
          >1:1문의</li>
          {/* <li
            onClick={() => {
              dispatch(alertModalSetting(!alert.open));
              setMyMenuOpen(!myMenuOpen);
            }}
          >정보수정</li> */}
          <li
            onClick={() => {
              dispatch(alertModalSetting({open : !alert.open, title: "로그아웃되었습니다."}));
              sessionStorage.clear();
              dispatch(tokenSetting({ accessToken: "" }));
              dispatch(profileSetting({ _id: "", name: "", picture: "", category: "", email: "" }));
              dispatch(loginSetting(false));
              // setMyMenuOpen(!myMenuOpen);
              dispatch(mymenuSetting(!mymenu.open));
              navigate('/zemiverse');
            }}
          >로그아웃</li>
        </ul>
      </div>
    </div>
  );
}

export default MyMenu;