import { useTranslation } from 'react-i18next';

import './exchange.scss';

const Exchange = () => {

  const { t } = useTranslation();

  return (
    <div className="container exchange">

      {/* content1/2 */}
      {[...Array(2)].map((data: any, idx: number) => (
        <div className={`content${idx + 1}`} key={idx}>
          <div className={`content${idx + 1}__inner`}>
            <p className="title">{t(`exchange_content${idx + 1}_title`)}</p>
            <p className="description">{t(`exchange_content${idx + 1}_description`)}</p>
          </div>
        </div>
      ))}

      {/* content3 */}
      <div className="content3">
        <div className="content3__inner">
          {[...Array(2)].map((data: any, idx: number) => (
            <div className="box" key={idx}>
              <div className="img"></div>
              <p className="title">{t(`exchange_content3_box${idx + 1}_title`)}</p>
              <p className="description">{t(`exchange_content3_box${idx + 1}_description`)}</p>
            </div>
          ))}
        </div>
      </div>

      {/* content4 */}
      <div className="content4">
        <div className="content4__inner">
          <p className="title">{t("exchange_content4_title")}</p>
          <p className="description">{t("exchange_content4_description")}</p>
          <div className="boxes">
            {[t("exchange_content4_box1"), t("exchange_content4_box2"), t("exchange_content4_box3")].map((data: any, idx: number) => (
              <div className="box" key={idx}>
                <p className="title">{data}</p>
                <div className="img"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Exchange;