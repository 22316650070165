import { useState } from "react";

const useSelect = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const _handleChange = (event: any) => {
    setValue(event);
  }

  return {
    value,
    onClick:_handleChange
  }
};

export default useSelect;