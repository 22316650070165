import { SelectBox } from "@components/common";
import './useAvatar.scss';
import useSelect from '@hooks/useSelect';

const UseAvatar = () => {

  const avatarList = [
    {id: 1, imageUrl: "/images/avatar.png", value: "멋쨍이", title: "멋쨍이", disable:false},
    {id: 2, imageUrl: "/images/avatar.png", value: "사자", title: "사자", disable:true},
    {id: 3, imageUrl: "/images/avatar.png", value: "호랑이", title: "호랑이", disable:false},
    {id: 4, imageUrl: "/images/avatar.png", value: "곰돌이", title: "곰돌이", disable:false},
    {id: 5, imageUrl: "/images/avatar.png", value: "뱀", title: "뱀", disable:false},
    {id: 6, imageUrl: "/images/avatar.png", value: "고양이", title: "고양이", disable:false},
    {id: 7, imageUrl: "/images/avatar.png", value: "강아지", title: "강아지", disable:false},
    
  ]

  const selectData = useSelect(avatarList[0]);

  return (
    <div className="avatar__container" >
      <div className="avatar__wrapper">
        <p className="avatar__label">대표 아바타 선택</p>
        
        {/* <input type="text" id="avatar" className="avatar" /> */}
        {/* <SelectBox name="avatar" classification data={avatarList} selectData={selectData} /> */}
        <SelectBox name="avatar" image data={avatarList} selectData={selectData} />
      </div>
      <div className="avatar__explanation">
        <p>∙ZEMIVERSE와 STUDIO에서 활동하실 대표 아바타를 선택해주세요.</p>
        <p>∙대표 아바타는 마이페이지 정보수정에서 변경하실 수 있습니다.</p>
      </div>
    </div>
  );
};

export default UseAvatar;