import classNames from 'classnames';

import {Outlet, useLocation} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import { ConfirmModal, ReportModal, StudioItemModal, AlertModal, MainGnb, MainHeader230622 } from '@components/index';

import { RootState, ModalState, AlertModalState } from "@store/index";

import './mainLayout.scss';

const MainLayout = () => {

  const location = useLocation();
  const modal:ModalState = useSelector((state: RootState) => state.modal);
  const alert:AlertModalState = useSelector((state: RootState) => state.alert);

  const { i18n } = useTranslation();
  
  return (
    <div className={classNames(`App`, i18n.language, location.pathname === "/zemiverse" ? "main" : "")}>
      {/* Header Components가 들어가는 부분 */}
      <MainHeader230622 />
      <div className="main_body">
        {/* side Nav가 들어가는 부분 */}
        {/* <MainGnb /> */}
        {/* Main Content */}
        <Outlet />
      </div>

      {/* Modal */}
      {(modal.open &&( modal.type === "warning" || modal.type === "danger" || modal.type === "success" || modal.type === "info")) && <ConfirmModal title={modal.title} description={modal.description} cancel="취소" type={modal.type} confirmButton={modal.confirmButton} confirmStyle={modal.confirmStyle} />}
      {(modal.open && modal.type === "report") && <ReportModal/>}
    {alert.open && <AlertModal/>}
      {(modal.open && modal.type === "createItem") && <StudioItemModal/>}
      
    </div>
  )
}

export default MainLayout;