export const getFirstPath = (url : string) => {
  var splitUrl : string[] = [];
  splitUrl = url.split('/');
  return splitUrl[1];
}

export const getLastPath = (url : string) => {
  var splitUrl : string[] = [];
  splitUrl = url.split('/');
  return splitUrl[2];
}

export const getIdPath = (url : string) => {
  var splitUrl : string[] = [];
  splitUrl = url.split('/');
  return splitUrl[3];
}