const LOGIN_SETTING = 'login/LOGIN_SETTING' as const;
const TOKEN_SETTING = 'login/TOKEN_SETTING' as const;
const PROFILE_SETTING = 'login/PROFILE_SETTING' as const;
const PROFILE_IMG_SETTING = 'login/PROFILE_IMG_SETTING' as const;

type LoginAction =
  | ReturnType<typeof loginSetting>
  | ReturnType<typeof tokenSetting>
  | ReturnType<typeof profileSetting>
  | ReturnType<typeof profileImgSetting>
  ;

export const loginSetting = (diff: boolean) => ({
  type: LOGIN_SETTING,
  payload: diff
});

export const tokenSetting = (diff: {accessToken: string}) => ({
  type: TOKEN_SETTING,
  payload: diff
});

export const profileSetting = (diff: {_id: string, name: string, picture: string, category: string, email: string}) => ({
  type: PROFILE_SETTING,
  payload: diff
});

export const profileImgSetting = (diff: string) => ({
  type: PROFILE_IMG_SETTING,
  payload: diff
});

export type LoginState = {
  login: boolean;
  category: string;
  email: string;
  name: string;
  picture: string;
  accessToken: string;
  _id: string;
};

const initialState: LoginState = {
  login: false,
  category: "",
  email: "",
  name: "",
  picture: "",
  accessToken: "",
  _id: ""
};

function login(
  state: LoginState = initialState,
  action: LoginAction
): LoginState {
  switch (action.type) {
    case LOGIN_SETTING:
      return { ...state, login: action.payload};
    case TOKEN_SETTING:
      return { ...state, accessToken: action.payload.accessToken};
    case PROFILE_SETTING:
      return { ...state, _id: action.payload._id, name: action.payload.name, picture: action.payload.picture, category: action.payload.category, email: action.payload.email};
    case PROFILE_IMG_SETTING:
      return { ...state, picture: action.payload}
    default:
      return state;
  }
}

export default login;