import { MainLayout, MetaTag, NotFound, SubLayout, StudioLayout } from "@components/index";
import LoginLayout from "@components/layout/LoginLayout/LoginLayout";
import { EventEnd, EventPage, Detail, EventProceeding, EventWinnerAnnouncement, FAQ, CommunityBoard, InquiryPage, InquiryTemporaryPage, Login, NoticePage, Register, RegisterDone, UpdatePage, Zemiverse, MyContents, Guide, StudioFAQ, Studio, InquiryWrite, Write, TermsPage, PersonalPolicy, MyPage, World, Exchange, Club, Download, Rental, Apk} from "@pages/index";
import { RouteObject } from "react-router-dom";

const path: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <SubLayout />,
        children: [
          { path: "zemiverse", element: <Zemiverse /> },
          { path: "world", element: <World /> },
          { path: "exchange", element: <Exchange /> },
          { path: "rental", element: <Rental /> },
          { path: "club", element: <Club /> },
          { path: "download", element: <Download /> },
          {
            path: "news",
            // element: <NoticePage />, 
            children: [
              { path: "notice", element: <NoticePage /> },
              { path: "notice/:id", element: <Detail /> },
              { path: "update", element: <UpdatePage /> },
              { path: "update/:id", element: <Detail /> },
              { path: "event", element: <EventPage /> },
              { path: "event/:id", element: <Detail /> },
              // { path: "event/proceeding", element: <EventProceeding /> },
              // { path: "event/end", element: <EventEnd /> },
              // { path: "event/announcement", element: <EventWinnerAnnouncement /> },
            ]
          },
          {
            path: "community",
            // element:
            children: [
              { path: "free-board", element: <CommunityBoard /> },
              { path: "free-board/:id", element: <Detail /> },
              { path: "tip-board", element: <CommunityBoard /> },
              { path: "tip-board/:id", element: <Detail /> },
              { path: "screenshot-board", element: <CommunityBoard /> },
              { path: "screenshot-board/:id", element: <Detail /> },
            ]
          },
          {
            path: "customer-service",
            // element:
            children: [
              { path: "faq", element: <FAQ /> },
              { path: "inquiry", element: <InquiryTemporaryPage /> },
            ]
          }
        ]
      },
      // {
      //   path: "apk",
      //   element: <SubLayout />,
      //   children: [
      //     { path: "", element: <Apk />}
      //   ]
      // },
      // {
      //   path: "studio",
      //   element: <StudioLayout />,
      //   children: [
      //     { path: "", element: <Studio /> },
      //     { path: "my-contents", element: <MyContents /> },
      //     // { path: "guide", element: <Guide /> },
      //     { path: "studio-faq", element: <StudioFAQ /> }
      //   ]
      // },
      {
        path: "",
        element: <SubLayout />,
        children: [
          { path: 'terms', element: <TermsPage /> },
          { path: 'policy', element: <PersonalPolicy /> },
        ]
      }
    ]
  },
  {
    path: "",
    element: <LoginLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: 'register', element: <Register/> },
      { path: 'register/done', element: <RegisterDone /> },
    ]
  },
  {
    path: "",
    children: [
      { path: 'terms2', element: <TermsPage /> },
      { path: 'policy2', element: <PersonalPolicy /> },
      { path: 'notice2', element: <NoticePage /> },
    ]
  },
  {
    path: "*",
    element: <LoginLayout/>,
    children: [
      { path: "*", element:<NotFound />}, 
      // { path: "test", element: <TestPage />}
    ]
  }
];

const loginPath: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <SubLayout />,
        children: [
          { path: "zemiverse", element: <Zemiverse /> },
          { path: "world", element: <World /> },
          { path: "exchange", element: <Exchange /> },
          { path: "rental", element: <Rental /> },
          { path: "club", element: <Club /> },
          { path: "download", element: <Download /> },
          // { path: "game/guide", element: <GameGuide /> },
          // { path: "game/mini", element: <MiniGameInfo /> },
          {
            path: "news",
            // element: <NoticePage />, 
            children: [
              { path: "notice", element: <NoticePage /> },
              { path: "notice/:id", element: <Detail /> },
              { path: "update", element: <UpdatePage /> },
              { path: "update/:id", element: <Detail /> },
              { path: "event", element: <EventPage /> },
              { path: "event/:id", element: <Detail /> },
              // { path: "event/proceeding", element: <EventProceeding /> },
              // { path: "event/end", element: <EventEnd /> },
              // { path: "event/announcement", element: <EventWinnerAnnouncement /> },
            ]
          },
          {
            path: "community",
            // element:
            children: [
              { path: "free-board", element: <CommunityBoard /> },
              { path: "free-board/:id", element: <Detail /> },
              { path: "free-board/write", element: <Write /> },
              { path: "tip-board", element: <CommunityBoard /> },
              { path: "tip-board/:id", element: <Detail /> },
              { path: "tip-board/write", element: <Write /> },
              { path: "screenshot-board", element: <CommunityBoard /> },
              { path: "screenshot-board/:id", element: <Detail /> },
              { path: "screenshot-board/write", element: <Write /> }
            ]
          },
          {
            path: "customer-service",
            // element:
            children: [
              { path: "faq", element: <FAQ /> },
              { path: "inquiry", element: <InquiryTemporaryPage /> },
              // 차후 업데이트 필요함
              // { path: "inquiry/:id", element: <Detail /> },
              // { path: "inquiry/write", element: <InquiryWrite /> }
            ]
          }
        ]
      },
      // {
      //   path: "studio",
      //   element: <StudioLayout />,
      //   children: [
      //     { path: "", element: <Studio /> },
      //     { path: "my-contents", element: <MyContents /> },
      //     // { path: "guide", element: <Guide /> },
      //     { path: "studio-faq", element: <StudioFAQ /> }
      //   ]
      // },
      {
        path: "",
        element: <SubLayout />,
        children: [
          { path: 'terms', element: <TermsPage /> },
          { path: 'policy', element: <PersonalPolicy /> },
        ]
      }
    ]
  },
  {
    path: "",
    element: <LoginLayout />,
    children: [
      // { path: "login", element: <Login /> },
      // { path: 'register', element: <Register/> },
      { path: 'register/done', element: <RegisterDone /> },
      { path: 'mypage', element: <MyPage /> },
    ]
  },
  {
    path: "",
    children: [
      { path: 'terms2', element: <TermsPage /> },
      { path: 'policy2', element: <PersonalPolicy /> },
    ]
  },
  {
    path: "*",
    element: <LoginLayout/>,
    children: [
      { path: "*", element:<NotFound />}, 
      // { path: "test", element: <TestPage />}
    ]
  }
];

const boardHeader = [
  {
    path: "notice",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "제목" },
      { id: 3, title: "작성일" },
      { id: 4, title: "조회수" }
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "공지" },
      { id: 2, title: "점검" },
      { id: 3, title: "상점" },
    ]
  },
  {
    path: "update",
    boardTitle: [],
    boardCategory: []
  },
  {
    path: "event",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "진행" },
      { id: 2, title: "예정" },
      { id: 3, title: "종료" },
    ]
  },
  {
    path: "free-board",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "제목" },
      { id: 2, title: "작성자" },
      { id: 3, title: "작성일" },
      { id: 4, title: "댓글수" },
      { id: 5, title: "좋아요" },
      { id: 6, title: "신고" },
      { id: 7, title: "조회수" }
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "조회수" },
      { id: 2, title: "좋아요수" },
      { id: 3, title: "댓글수" },
    ]
  },
  {
    path: "tip-board",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "제목" },
      { id: 2, title: "작성자" },
      { id: 3, title: "작성일" },
      { id: 4, title: "댓글수" },
      { id: 5, title: "좋아요" },
      { id: 6, title: "신고" },
      { id: 7, title: "조회수" }
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "조회수" },
      { id: 2, title: "좋아요수" },
      { id: 3, title: "댓글수" },
    ]
  },
  {
    path: "screenshow-board",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "조회수" },
      { id: 2, title: "좋아요수" },
      { id: 3, title: "댓글수" },
    ]
  },
  {
    path: "report",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "신고 원문" },
      { id: 3, title: "작성자" },
      { id: 4, title: "신고 횟수" },
      { id: 5, title: "처리상태" }
    ],
    boardCategory: []
  },
  {
    path: "faq",
    boardTitle: [],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "ZEMIVERSE" },
      { id: 2, title: "Studio" },
    ]
  },
  {
    path: "inquiry",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "구분" },
      { id: 2, title: "제목" },
      { id: 3, title: "작성자" },
      { id: 4, title: "작성일" },
      { id: 5, title: "처리상태" }
    ],
    boardCategory: [
      { id: 0, title: "전체" },
      { id: 1, title: "문의" },
      { id: 2, title: "버그 제보" },
      { id: 3, title: "신고" },
    ]
  },
  {
    path: "list",
    boardTitle: [
      { id: 0, title: "순서" },
      { id: 1, title: "E-mail" },
      { id: 2, title: "소셜" },
      { id: 3, title: "닉네임" },
      { id: 4, title: "계정 연동" },
      { id: 5, title: "연락처" },
      { id: 6, title: "최종 접속일" },
      { id: 7, title: "접속 횟수" },
      { id: 8, title: "상태" },
    ],
    boardCategory: []
  },
  {
    path: "account",
    boardTitle: [
      { id: 0, title: "구분" },
      { id: 1, title: "ID" },
      { id: 2, title: "담당자명" },
      { id: 3, title: "최근접속일" },
      { id: 4, title: "관리" }
    ],
    boardCategory: []
  },
];

export { path, boardHeader, loginPath };