import { useTranslation } from "react-i18next";

/**
 * 현재 리스트에 아무것도 없을 경우 보여주는 것
 */
const NoList = () => {

  const { t } = useTranslation();

  return (
    <div className="no_list">
      <div className="img"></div>
      <p className="title">{t("no_list_title")}</p>
      <p className="description">{t("no_list_description")}</p>
    </div>
  );
}

export default NoList;