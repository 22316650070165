import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import axios from "axios";

import { CategoryBox, BoardList, PageTop, InputBox, SelectBox, NormalButton, Loading, SearchMobileModal, NoList } from "@components/index";
import { LoadingState, RootState, loadingSetting, SearchModalState, searchModalSetting, LoginState, PathState } from "@store/index";
import useSelect from '@hooks/useSelect';

import { REACT_APP_SERVER_URL } from '../../../../shared/utils';

import { ReactComponent as SearchIcon } from '@assets/images/layer/search.svg';
import { useTranslation } from "react-i18next";

interface keyInterface {
  id: number
  text: string
}

const FreeBoardPage = () => {

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const CategoryList = [
    { id: 0, title: t("freeboard_category_2_1") },
    { id: 1, title: t("freeboard_category_2_2") },
    { id: 2, title: t("freeboard_category_2_3") },
  ];

  const CategoryListData = [
    { id: 0, title: t("freeboard_category_1_1") },
    { id: 1, title: t("freeboard_category_1_2") },
    // { id: 2, title: t("freeboard_category_1_3") }
  ];

  const [listData, setListData] = useState([]); // Axios로 받아온 데이터 저장
  const [currentList, setCurrentList] = useState([]); // 현제 페이지의 리스트
  const [page, setPage] = useState(1); // 현재 페이지
  const handlePageChange = (page: number) => { setPage(page) };
  const [listPerPage] = useState(12); // 페이지 당 리스트 개수
  const indexOfLastList = page * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const [category, setCategory] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const selectData = useSelect(CategoryList[0]);

  const [keywords, setKeywords] = useState<keyInterface[]>([]);

  const path: PathState = useSelector((state: RootState) => state.path);
  const loading: LoadingState = useSelector((state: RootState) => state.loading);
  const searchModal: SearchModalState = useSelector((state: RootState) => state.searchModal);
  const login: LoginState = useSelector((state: RootState) => state.login);

  const [boardType, setBoardType] = useState("");
  const [pathType, setPathType] = useState("");

  // 검색어 추가
  const handleAddKeyword = (text: string) => {
    if (text === null || text === undefined || text === "") {

    } else {
      const newKeyword = {
        id: Date.now(),
        text: text,
      };
      setKeywords([newKeyword, ...keywords]);
    }
  };

  // 단일 검색어 삭제
  const handleRemoveKeyword = (id: number) => {
    const nextKeyword = keywords.filter((keyword) => {
      return keyword.id !== id;
    });
    setKeywords(nextKeyword);
  };

  //검색어 전체 삭제
  const handleClearKeywords = () => {
    setKeywords([]);
  };

  // ① window 즉, 브라우저가 모두 렌더링된 상태에서 해당 함수를 실행할 수 있도록 작업
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const result = localStorage.getItem('keywords') || '[]'
      setKeywords(JSON.parse(result))
    };
  }, []);

  // ② keywords 객체를 의존하여, 변경될 경우 새롭게 localStroage의 아이템 'keywords'를 세팅한다
  useEffect(() => {
    localStorage.setItem('keywords', JSON.stringify(keywords));
  }, [keywords]);

  const getApiUrl = (category: number, searchValue?: string, status?: string) => {

    const searchFields: any = {
      0: "title",
      1: "content",
      2: "title_content"
    };
    
    const searchField = searchFields[selectData.value.id];

    let url = `${REACT_APP_SERVER_URL}/${boardType}/${pathType}`;
    const queryParams = [];
    if (category === 0) {
      // if (path.nowPath === 'event') {
        // queryParams.push(`status=${category === 1 ? "진행" : category === 2 ? "예정" : "종료"}`);
      // } else {
      //   queryParams.push(`category=${category}`);
      // }
      queryParams.push(`new=true`);
    } else if (category === 1) {
      queryParams.push(`hot=true`);
    } 
    // else if (category === 2) {
    //   queryParams.push(`comment=true`);
    // }
    if (searchValue) {
      queryParams.push(`${searchField}=${searchValue}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }
    return url;
  };

  const fetchApi = async (url: string) => {
    dispatch(loadingSetting(true));
    try {
      const { data } = await axios.get(url, {
      });
      setListData(data.response.data);
    } catch (e) {
      console.error(e);
      // alert("개발팀에 문의해주세요.");
    }
    dispatch(loadingSetting(false));
  };

  const fetchData = async () => {
    const url = getApiUrl(category, searchValue);
    fetchApi(url);
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnterKey = e.key === 'Enter';
    if (isEnterKey) {
      handleAddKeyword(searchValue);
      if (searchValue !== "" && searchModal.open) {
        dispatch(searchModalSetting(!searchModal.open));
      }
      fetchData();
      setPage(1);
    }
  };

  const resetPage = () => {
    // _nowHeader();
    setCategory(0);
    setSearchValue("");
    setPage(1);
  };

  useEffect(() => {
    let a = (location.pathname === "/community/free-board" || location.pathname === "/community/tip-board" || location.pathname === "/community/screenshot-board") ? 'community' : "";
    let b = location.pathname === "/community/free-board" ? `forum` : location.pathname === "/community/tip-board" ? `tip` : location.pathname === "/community/screenshot-board" ? `screenshot` : ``;
    setBoardType(a);
    setPathType(b);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (boardType === "" || pathType === "") {
    } else {
      resetPage();
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathType]);

  useEffect(() => {
    if (boardType === "" || pathType === "") {

    } else {
      setSearchValue("");
      fetchData();
      setPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (listData === null || listData === undefined) {
    } else {
      setCurrentList(listData.slice(indexOfFirstList, indexOfLastList));
    }
  }, [indexOfFirstList, indexOfLastList, page, listData]);

  return (
    <>
      <div className={path.nowPath === "screenshot-board" ? "screenshot" : "freeboard"}>
        <div className={`${path.nowPath === "screenshot-board" ? "screenshot" : "freeboard"}__inner`}>
          <PageTop title={location.pathname === '/community/free-board' ? t("freeboard_title") : location.pathname === '/community/tip-board' ? t("tipboard_title") : t("screenshotboard_title")} />
          <div className="board">
            <div className="board__top">
              <CategoryBox data={CategoryListData} category={category} setCategory={setCategory} />
              <div className="right">
                <SelectBox
                  name="category"
                  data={CategoryList}
                  selectData={selectData}
                  classification
                />
                <InputBox
                  value={searchValue}
                  onChange={(e: any) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder={t("notice_placeholder")}
                  onKeyPress={handleOnKeyPress}
                  icon
                />
                <SearchIcon
                  onClick={() => {
                    dispatch(searchModalSetting(!searchModal.open));
                  }}
                />
              </div>
            </div>
            <div className="board__body">
              {loading.isLoading ? (
                <Loading />
              ) : currentList.length <= 0 || currentList === null || currentList === undefined ? (
                <NoList />
              ) : (
                currentList.map((data, idx) => {
                  return (
                    <BoardList data={data} key={idx} />
                  );
                })
              )}
            </div>
            <div className="board__bottom">
              {currentList === null || currentList === undefined || currentList.length <= 0 ? (
                null
              ) : (
                <>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={listPerPage}
                    totalItemsCount={listData === null || listData === undefined ? 0 : listData.length}
                    pageRangeDisplayed={10}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={handlePageChange}
                    innerClass="board__pagination"
                    activeClass="selected"
                    itemClassFirst="normal_button box sm secondary_gray"
                    itemClassPrev="normal_button box sm secondary_gray"
                    itemClassNext="normal_button box sm secondary_gray"
                    itemClassLast="normal_button box sm secondary_gray"
                  />
                  <div className="mobile_pagination">
                    <NormalButton
                      title="<"
                      size="sm"
                      hierarchy="secondary_gray"
                      buttonStyle="box"
                      onClick={() => {
                        if (page > 1) {
                          handlePageChange(page - 1);
                        }
                      }}
                    />
                    {listData === null || listData === undefined || listData.length <= 0 ? (
                      null
                    ) : (
                      <p className="page">Page {page} of {(listData.length / listPerPage) < 1 ? "1" : Math.ceil(listData.length / listPerPage)}</p>
                    )}

                    <NormalButton
                      title=">"
                      size="sm"
                      hierarchy="secondary_gray"
                      buttonStyle="box"
                      onClick={() => {
                        if (page < Math.ceil(listData.length / listPerPage)) {
                          handlePageChange(page + 1);
                        }
                      }}
                    />
                  </div>
                </>
              )}
              {login.login ? <NormalButton
                title={t("write")}
                size="md"
                hierarchy="primary"
                icon="false"
                buttonStyle="box"
                onClick={() => {
                  const boardPath = location.pathname.split('/')[2];
                  navigate(`/community/${boardPath}/write`);
                }}
              /> : null}
            </div>
          </div>
        </div>
      </div>
      {(searchModal.open)
        && <SearchMobileModal
          searchValueList={keywords}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleOnKeyPress={handleOnKeyPress}
          deleteAllSearchValue={handleClearKeywords}
          deleteSearchValue={handleRemoveKeyword}
        />
      }
    </>
  );
};

export default FreeBoardPage;