import { useState, useMemo } from 'react';
import axios from 'axios';
import { REACT_APP_SERVER_URL } from '@utils/config';

const useInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);
  const [logMessage, setLogMessage] = useState('');
  const [logType, setLogType] = useState('');

  const _handleChange = (e: any) => {
    setValue(e.target.value);
  }

  const _handleCheckNickname = async () => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_URL}/user/nickname/${value}/check`);
      if (data.response.data.status === "success") {
        if(data.response.data.message === "사용불가능 합니다. 특수문자 포함되어 있습니다.") {
          setLogMessage("입력된 닉네임은 사용불가 합니다.");
          setLogType("error");
        } else if (data.response.data.message === "사용가능합니다.") {
          setLogMessage("입력된 닉네임은 사용가능 합니다.");
          setLogType("info");
        } 
      } else if (data.response.data.status === "warning") {
        if (data.response.data.message === "이미 사용중 입니다.") {
          setLogMessage("입력된 닉네임은 사용중입니다.");
          setLogType("warn");
        } else {
          setLogMessage("입력된 닉네임은 사용불가 합니다.");
          setLogType("error");
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useMemo(_handleCheckNickname, [value]);

  return {
    value, 
    logMessage,
    logType,
    onChange:_handleChange,
  };
};

export default useInput;