import { useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import classNames from "classnames";

import { NormalButton } from "@components/index";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import "./zemiverse.scss";

declare var process: any;

const Zemiverse = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [scrollTop, setScrollTop] = useState(0);

  return (
    <div className="container zemiverse">
      <div
        className="container__inner"
        // onScroll={handleScroll}
      >
        {/* content1 */}
        <div className="content1">
          <div className="content1__inner">
            <p className="title">{t("zemiverse_content1_title")}</p>
            <p className="description">{t("zemiverse_content1_description")}</p>
            <NormalButton
              title={t("zemiverse_content1_button")}
              icon="trailing"
              buttonStyle="round"
              hierarchy="primary"
              size="txl"
              onClick={() => {
                // dispatch(alertModalSetting(!alert.open));
                navigate(`/download`);
              }}
            />
          </div>
          <div className={classNames("box__scroll__down", { top: scrollTop === 0 })}>
            <p>{t("zemiverse_content1_scroll")}</p>
            <div className="box">
              <div className="bar"></div>
            </div>
          </div>
        </div>

        {/* content2 */}
        <div className="content2">
          <div className="content2__inner">
            <p className="title">{t("zemiverse_content2_title")}</p>
            <p className="description">{t("zemiverse_content2_description")}</p>
            <Swiper
              modules={[Pagination]}
              spaceBetween={24}
              slidesPerView={window.innerWidth > 1450 ? 3.3 : window.innerWidth > 760 ? 1.2 : 1}
              initialSlide={2}
              pagination={{
                clickable: true,
              }}
              // loop
              centeredSlides={true}
            >
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
            </Swiper>
            <NormalButton
              title={t("zemiverse_content2_button")}
              icon="trailing"
              buttonStyle="box"
              hierarchy="primary_line"
              size="txl"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/world");
              }}
            />
          </div>
        </div>

        {/* content3 */}
        <div className="content3">
          <div className="content3__inner">
            <p className="title">{t("zemiverse_content3_title")}</p>
            <p className="description">{t("zemiverse_content3_description")}</p>
            <div className="player__box">
              <ReactPlayer
                url={`${process.env.PUBLIC_URL}/videos/avatar__custom__woman.mp4`}
                className="player"
                style={{ objectFit: "cover" }}
                playing
                muted
                loop
                controls={false}
                playsinline
              />
              {/* <div className="player">
                img className="video" src={`${process.env.PUBLIC_URL}/videos/avatar__custom__final.gif`} alt="" />
              </div> */}
            </div>
          </div>
        </div>

        {/* content4 */}
        <div className="content4">
          <div className="content4__inner">
            <p className="title">{t("zemiverse_content4_title")}</p>
            <p className="description">{t("zemiverse_content4_description")}</p>
            <div className="boxes">
              {[...Array(3)].map((data: any, idx: number) => (
                <div className="box" key={idx}>
                  <p className="title">{t(`zemiverse_content4_box${idx + 1}_title`)}</p>
                  <p className="description">{t(`zemiverse_content4_box${idx + 1}_description`)}</p>
                  <div className="image"></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* content5 */}
        <div className="content5">
          <div className="content5__inner">
            <div className="top">
              <p className="title">{t("zemiverse_content5_title")}</p>
              <NormalButton
                title={t("zemiverse_content5_button")}
                icon="trailing"
                buttonStyle="round"
                size="txl"
                hierarchy="secondary_color"
                onClick={() => {
                  // dispatch(alertModalSetting(!alert.open));
                  navigate(`/exchange`);
                }}
              />
            </div>
            <div className="bottom">
              {[...Array(3)].map((data: any, idx: number) => (
                <div className="box" key={idx}>
                  <div className="image"></div>
                  <p className="title">{t(`zemiverse_content5_box${idx + 1}_title`)}</p>
                  <p className="description">{t(`zemiverse_content5_box${idx + 1}_description`)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* content6 */}
        <div className="content6">
          <div className="content6__inner__bg">
            {[...Array(11)].map((data: any, idx: number) => (
              <div className={`img${idx + 1}`} key={idx}></div>
            ))}
          </div>
          <div className="content6__inner">
            <div className="left"></div>
            <div className="right">
              <p className="title">{t("zemiverse_content6_title")}</p>
              <p className="description">{t("zemiverse_content6_description")}</p>
              <NormalButton
                title={t("zemiverse_content6_button")}
                buttonStyle="round"
                size="txl"
                icon="trailing"
                hierarchy="secondary_color"
                onClick={() => {
                  navigate("/rental");
                }}
              />
            </div>
          </div>
        </div>

        {/* content7 */}
        <div className="content7">
          <div className="content7__inner">
            <div className="left">
              <p className="title">{t("zemiverse_content7_title")}</p>
              <p className="description">{t("zemiverse_content7_description1")}</p>
              <p className="description">{t("zemiverse_content7_description2")}</p>
              <NormalButton
                title={t("zemiverse_content7_button")}
                icon="trailing"
                buttonStyle="round"
                size="txl"
                hierarchy="secondary_color"
                onClick={() => {
                  navigate("/club");
                }}
              />
            </div>
            <div className="right"></div>
          </div>
        </div>

        {/* content8 */}
        <div className="content8">
          <div className="content8__inner">
            <div className="left"></div>
            <div className="right">
              <p className="title">{t("zemiverse_content8_title")}</p>
              <p className="description">{t("zemiverse_content8_description")}</p>
            </div>
          </div>
        </div>

        {/* content9 */}
        <div className="content9">
          <div className="content9__inner">
            <p className="title">{t("zemiverse_content9_title")}</p>
            <div className="box">
              <NormalButton
                title={t("zemiverse_content9_button1")}
                icon="trailing"
                buttonStyle="round"
                size="txl"
                hierarchy="secondary_color"
                onClick={() => {
                  // dispatch(alertModalSetting(!alert.open));
                  navigate(`/download`);
                }}
              />
              <NormalButton
                title={t("zemiverse_content9_button2")}
                icon="trailing"
                buttonStyle="round"
                size="txl"
                hierarchy="secondary_color"
                onClick={() => {
                  navigate("/customer-service/faq");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Zemiverse;
